.manageUsers__personalInfo--checkbox {
  .ant-form-item-control {
    flex-direction: initial;
  }
  div:last-child {
    display: flex;
  }
}

.roleAssignment__table--path {
  font-weight: 500;
  margin-bottom: 18px;
  //background-color: #5cc9dd;
  //border-radius: 5px;
}

.roleAssignment__table--pathItem {
  * {
    color: #B7B7B7 !important;
  }
}
.roleAssignment__table--leftTable {
  margin-top: 40px;
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: @input-dark-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @text-color;
    border-radius: 10px;
  }
}

.roleAssignment__table--rightTable {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: @input-dark-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @text-color;
    border-radius: 10px;
  }
}
.roleAssignment__table--leftTable .ant-table-empty .ant-table-thead .ant-table-cell {
  border-bottom: @main-border-separator
}

.roleAssignment__table--leftTable .ant-table-thead .ant-table-cell {
  border-bottom: @main-border-separator
}

.roleAssignment__table--leftTable .ant-table-empty .ant-table-tbody .ant-table-cell {
  border-bottom: transparent;
}

.roleAssignment__table--leftTable table tbody .ant-table-row.ant-table-row-level-0:hover > td {
  background-color: transparent;
}

.roleAssignment__table--leftTable .ant-table-tbody > tr > td {
  border-bottom: transparent;
  border-top: 0px solid transparent;
  color: @primary-color;
}

.roleAssignment__table--rightTable table tbody .ant-table-row.ant-table-row-level-0:hover > td {
  background-color: rgba(255, 255, 255, 0.03);
}


.roleAssignment__row {
  .ant-tabs {
    overflow: visible;
  }

  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: @input-dark-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @text-color;
    border-radius: 10px;
  }

  .roleAssignment__table--backButton {
    margin-bottom: 6px;
  }

  .roleAssignment__table--tabs {
    .ant-tabs-nav{
      margin: 0;
    }
  }

  .roleAssignment__table--headers {
    width: 100%;
    height: 100px;
    padding: calc(100px / 2.7) 0;
  }

  .roleAssignment__table--rightTable, .roleAssignment__table--leftTable {
    .ant-table-pagination.ant-pagination{
      float: none;
      text-align: initial;
    }
  }

  .roleAssignment__table--cell-clickable {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.roleAssignment__table--tabs.ant-tabs-card .ant-tabs-nav::before {
  border-bottom: @main-border-separator;
}

.roleAssignment__table--tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-nav-list {
  border-left: @main-border-separator;
  border-right: @main-border-separator;
  .ant-tabs-tab {
    margin: 0;
    border-radius: 0;
    background-color: transparent;
    font-size: 16px;
    border: none;
    border-bottom: @main-border-separator;
    border-top: @main-border-separator;
  
    &:hover {
      background-color: #0092D7;
      .ant-tabs-tab-btn {
        color: #FFFFFF;
      }
    }
  }
}

.roleAssignment__table--tabs.ant-tabs-card .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #0092D7;
  
  .ant-tabs-tab-btn {
    color: #FFFFFF;
  }
}

.manageUsers__userDetails--auditInfo {
  > * {
    padding: 0 !important;
    margin-left: 16px !important;
  }
}

.userPrograms {
  max-width: 100%;

  td.ant-table-cell {
    padding: 0;
  }
  .userPrograms__column {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-select-dropdown {
      min-width: 400px !important;
      width: 400px !important;
    }

    &.-header {
      height: 100px;
      font-size: @font-size-base;
    }
  }

  .userPrograms__select {
    text-align: center;
    width: 90%;
    .ant-select-selection-overflow {
      justify-content: center;
    }
  }
}

.userPrograms .ant-table .ant-table-container table tbody tr.ant-table-row-selected td {
  background-color: transparent;
}

.userPrograms .ant-table {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: @input-dark-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @text-color;
    border-radius: 10px;
  }
}

.roleAssignment-table-header-label {
  margin-right: 5px;
}

.roleAssignment__table--rightTable tbody > tr > td {
  padding: 0;
}

.roleAssignment__table--cell-button {
  border: 0;
  height: 31px;
  background-color: transparent;
  width: 100%;
  
  &::after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }

  &:focus {
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;
  }

  span {
    color: #038fde !important;
  }
}

.user-details-col {
  padding: 0 !important;
}
