/*
        Customers Page Styles
================================
*/

/* GENERAL */
ul {
	list-style: none;
}

/* Sorter */
.ant-table-thead {
	>tr {
		>th {
			.ant-table-column-sorter {
				.ant-table-column-sorter-inner {
					color: @white-color;
				}
				display: none;
			}
			&:hover {
				background-color: transparent !important;
				border-right: 1px solid transparent;
			}
			background-color: transparent !important;
			border-right: 1px solid transparent;
		}
	}
}
.customer-edit-branding-card {
  .colorPickerWrapper {
    border: 1px solid @grey-btn;
    padding: 0px 8px;
    border-radius: 5px;
  }
  .uploadImgLabel {
    font-size: 0.8em;
  ;
  }
}

.customer-edit-advanced-reports-card,
.customer-edit-interconnect-card,
.customer-edit-clinician-sidebar-card,
.customer-edit-perf-serve-card {
  .baseLineAlignedItems {
    align-items: baseline;
    padding: 5px 0;
  }
}
.customer-edit-info-card,
.customer-edit-clinician-sidebar-card,
.customer-edit-attributes-card, .customer-edit-branding-card{
  .baseLineAlignedItems {
    align-items: center;
    padding: 5px 0;
  }
}

.customer-edit-info-card, .customer-edit-branding-card{
  .baseLineAlignedItems {
    padding: 5px 0 5px 0px;
  }
  .customerOrgName {
    border: 1px solid @grey-btn;
    padding: 8px;
    border-radius: 5px;

  }
}

.baseLineAlignedItems .ant-input {
  height: 40px;
}

.select-accreditation .ant-select-selector {
  height: 40px;
}

.customer-edit-info-card {
  .error {
    margin-top: 10px;
    color: @magenta-icon;
  };
}

.edit-apd-details .form-item-row.notes {
  padding: 8px 0;
}

.edit-apd-details .ant-input-affix-wrapper-disabled {
  opacity: 0.7;
}

.disabled-row{
  display: none;
}

.ant-input-affix-wrapper.interconnect-password {
  padding: 0;
  border: unset;
  background-color: transparent;
  &:focus, &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    input.ant-input {
      box-shadow: 0 0 0 2px rgb(3 143 222 / 20%) !important;
    }
  }
}