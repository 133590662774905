.cil-modal {
  .ant-row {
    margin: 10px 0;
  }
}

.profile {
  padding-right: 0 !important;
  .avatar {
    padding: 15px;
  }
  &::before {
    content: '';
  }
}

.ant-layout-header {
  .ant-select-dropdown {
    background-color: rgba(12, 42, 68, 0.9);
    box-shadow: 5px 5px 10px 0 rgb(0, 0, 0 / 75%);
  }
  .ant-select-item {
    font-size: 16px;
    color: @label-text-color;
    transition: all .3s;
    &:hover {
      color: #0092D7
    }
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }
}

.about-dialog {
  .caregility-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    img {
      width: 150px;
    }
  }
  .caregility-info {
    margin-bottom: 40px;
  }
}

.no-selectable-element {
  -webkit-user-select: none;       
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}

.no-draggable {
  -webkit-user-drag: none;
}

.logout-btn {
  padding: 0 !important;
  &::before {
    content: '';
  }
}
