.users-top-filters {
    align-items: flex-end;
    .users-input {
        height: 40px;
    }
    .role-input {
        max-height: 130px;
        overflow-y: auto;
        border-radius: 7px;
        .ant-select-selector {
            cursor: pointer !important;
            .ant-select-selection-item {
               background-color: #C8C8C8;
               color: #646464;
               border: none;
            }
        }

        .ant-select-selection-placeholder {
            color: #7D7D7D;
        }

        .ant-select-selection-search {
            height: 40px;
        }

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
          background-color: @input-dark-bg;
        }
        
        &::-webkit-scrollbar-thumb {
          background-color: @text-color;
          border-radius: 10px;
        }
        .ant-select-selection-search-input {
            display: none;
        }
        .ant-select-selection-overflow-item {
            max-width: 75%;
        }
    }
    .role-input.ant-select-focused {
        box-shadow: 0px 0px 0px 2px rgba(3, 143, 222, 0.2);
    }
    .clear-filter-btn {
        height: 100%;
    }
    .ant-cascader-menus {
        width: 100%;
        .ant-cascader-menu {
            width: 100%;
            min-width: 10px;
        }
    }
}

.check-user-role {
    display: none;
}

.ant-cascader-menus {
    width: 100%;
    .ant-cascader-menu {
        width: 100%;
        min-width: 10px;
    }
}

.listings-top-filter .filter-inputs {
    width: 100%;
    .users-input {
        max-width: 160px;
        min-width: 120px;
    }
}

