@import './uheVariables.less';
@import './uheMixins.less';

html {
  font-size: 14px;
}

.gx-sub-popover li:not(:last-child) {
  align-items: center;
  display: flex;
}

// Titles
.uhe-title {
  .title-txt(uppercase);
}

.page-title {
  font-size: @txt-2xl;
}

.gx-card {
  background: #09365A !important;
  .ant-card-head {
    .ant-card-head-title {
      white-space: normal;
      font-weight: @txt-normal;
      color: @primary-title;
    }
  }
}

// Topbar
.topbar-title {
  color: #0092D7;
  text-transform: @txt-up;
  font-size: @txt-3lg;
  text-align: center;
}

// Sidenav
.gx-app-sidebar {
  .gx-icon-btn {
    transition: .3s;
    &:hover {
      background: @sidenav-toggle;
    }
  }
}

.gx-layout-sider-dark {
  .gx-layout-sider-header {
    display: flex;
    justify-content: center;
    padding: 0;
    background: transparent;

  }
}

.gx-avatar-name {
  font-size: @txt-2md;
  color: @sidenav-item;
}

.submenu-item .ant-menu-submenu-title {
  font-size: @txt-md!important;
}

.ant-menu-inline {
  .ant-menu-submenu-title {
    font-size: @txt-lg;
    &:active {
      background: transparent;
    }
  }
  .ant-menu-item {
    font-size: @txt-md;
    .anticon {
      margin-right: 12px;
    }
  }
}
.ant-menu-inline .ant-menu-item-group .ant-menu-item-group-title .group-item img {
  width: 11%;
  margin-right: 20px;
}

.ant-menu {
  .ant-menu-dark {
    .ant-menu-item > a {
      color: @sidenav-item;
    }
  }
}

// Filters
.filter-inputs {
  display: flex;
  height: 40px !important;
  .ant-cascader-picker:not(:last-child),
  .ant-select {
    margin-right: 15px;
  }
}

.reset-btn {
  margin: auto;
}

.filter-btn {
  margin-top: 1rem;
  text-align: center;
  .reset-btn {
    .reset();
  }
}
.clear-filter-btn.reset-btn {
  margin: 0;
  height: 100%;
  border: none;
  font-size: 16px;
  font-weight: bold;
}

.clear-filter-btn.reset-btn:not([disabled]) {
  background-color: #c8c8c8;
  color: #646464;
  &:hover {
    background-color: #E6E6E6;
    border: none;
  }
}
.clear-filter-btn.reset-btn[disabled] {
  background-color: #505050;
  color: #282828;
}

// Tables
.gx-card {
  .ant-card-head {
    .ant-card-head-title {
      font-size: @txt-3lg;
      text-transform: none;
    }
  }
}

th,
td {
  white-space: nowrap;
}

.uhe-table-header {
  h2,
  p {
    margin: 0;
  }
}

.mandatory-field::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.uhe-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px !important;
  color: #0092D7 !important;
  .uhe-title {
    font-size: @txt-2lg;
  }
}

.uhe-table-header.developer {
  justify-content: end;
}

.uhe-table-td {
  display: flex;
  justify-content: space-between;
}

.filter-boxes .ant-table-tbody .ant-table-cell {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-table-tbody .ant-table-cell {
  padding-left: 17px !important;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  padding: 10px;
  overflow-wrap: break-word;
}

.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  color: @white-color;
}

.ant-table-thead > tr > th .ant-table-column-sorter {
  display: none;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.ant-table-pagination {
  &.ant-pagination {
    float: none;
    text-align: center;
  }
}

.ant-table-pagination.ant-pagination {
  float: none;
  text-align: center;
}

.configuration {
  /* Filter Inputs */
  .filter-inputs {
    .ant-cascader-picker {
      margin-right: 15px;
    }
    display: inline-flex;
  }
  /* Reset Button */
  .customers-filter-box,
  #uheBedCart {
    margin-top: 15px;
    .filter-boxes {
      padding-bottom: 15px !important;
    }
    .filter-btn {
      display: inline-flex;
      text-align: left;
    }
  }
}

.ant-select {
  width: 100%;
}

.ant-btn {
  margin-bottom: 0px;
}

/* Send Button */
.sendButton {
  width: 100%;
  background: #0092D7 !important;
  color: #FFFFFF !important;
  border-color: transparent !important;
  font-size: 16px;
  font-weight: bold;
  &[disabled] {
    background-color: #003A56 !important;
    color: #666666 !important;
  }
  &[disabled]:hover {
    background-color: #003A56 !important;
    color: #666666 !important;
  }
}

.sendButton:not([disabled]):hover{
  background-color: #49B2FF !important;
  color: #FFFFFF !important;
}
.sendButton{
  margin-left: 10px;
}

.sendButton.viewButton,
.baseLineAlignedItems .sendButton {
  background-color: #44B449 !important;
  &:hover {
    background-color: #44E649 !important;
  }
  &[disabled] {
    background-color: #1B481D !important;
    color: #666666 !important;
  }
}

.sendButton.system {
  background-color: #44B449 !important;
  &:hover {
    background-color: #44E649 !important;
  }
  &[disabled] {
    background-color: #1B481D !important;
    color: #666666 !important;
  }
}

.sendButton2{
  width: 100%;
  background: #0092D7 !important;
  color: #FFFFFF !important;
  border-color: transparent!important;
  font-size: 16px;
  &:not([disabled]):hover {
    background-color: #49B2FF !important;
    color: #FFFFFF;
  }
  &[disabled] {
    background-color: #003A56 !important;
    color: #666666 !important;
  }
  &[disabled]:hover {
    background-color: #003A56 !important;
    color: #666666 !important;
  }
}

.system .save-icon {
  margin-bottom: 15px;
}

.iObserver-btn, .providers-table-btn {
  border: 1px solid transparent!important;
  background: transparent!important;
  padding: 10px!important;
  height: 0px!important;
  display: flex;
    align-items: center;
}

.upload {
  width: 100%;
}

.ant-upload.ant-upload-select {
  width: 100%;
  .ant-btn:not([disabled]) {
    background-color: #C8C8C8;
    color: #646464;
    border: none;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      background-color: #E6E6E6;
    }
    span {
      color: #646464;
    }
  }
  .ant-btn[disabled] {
    background-color: #505050;
    color: #282828;
    font-size: 16px;
    border: none;
    span {
      color: #282828;
    }
  }
}

.icon-sweet-alert {
  color: #3490CD;
}

.icon-error {
  color: @main-red-color;
}

.action-butons {
  justify-content: space-around;
  margin-top: 5px;
  .edit-icon {
    margin-right: 15px !important;
  }
}

.system .check-icon {
  font-size: 17px!important;
  color: #a3c928!important;
}

.system .icon-close-circle {
  font-size: 17px!important;
  color: @main-red-color !important;
}

.icon-trash {
  color: @main-red-color !important;
}

.icon-reset-password {
  color: #3490CD !important;
}

.icon-forgot-password {
  color: #A5C04F !important;
}

.ant-card-head-title {
  white-space: normal;
  font-weight: @txt-normal;
  color: @primary-title;
  font-size: @txt-3lg;
}

.check-icon {
  color: #a3c928;
}

.prev-icon {
  background-color: #0092D8;
  color: @hor-nav-text-color;
  border-color: transparent;
}

.prev-icon:hover {
  background-color: #0386c2;
  color: @hor-nav-text-color;
}
.tableForm .prev-icon {
  display: none;
}

//Error Message
.errorMessage {
  color: #CA0B00;
}

.custom-error-message {
  color: #ED67A4;
}

.action-butons .ant-btn {
  height: auto;
  padding: 0 0;
  border-width: 0;
  margin-bottom: 5px;
  .icon-close-circle {
    color: #ED67A4 !important;
  }
}

.action-butons .ant-btn.login-as {
  background: transparent;
}

.updateButton {
  background: @main-green-color;
}

.updateButton:hover {
  background-color: #A5C04F !important;
  color: #000;
  border-color: transparent;
}

.ant-btn {
  i {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-select {
  width: 100%;
}

.buttons-container {
  display: flex;
}

.page-icons {
	color: #000;
	font-size: 1.1rem;
	padding: 5px 10px 5px 10px !important;
	border-radius: 50%;
	cursor: pointer;
}

.page-icon-plus {
  display: flex;
  align-items: center;
  background-color: @main-green-color !important;
  border-radius: 20px;
	&:hover {
		background-color: #A5C04F !important;
		color: #000;
		border-color: transparent;
	}
}

.page-icon-bulk-upload {
  display: flex;
  align-items: center;
  background-color: #d3c014 !important;
  border-radius: 20px;
  margin-left: 10px;
  &:hover {
    background-color: #c4b216 !important;
    color: #000;
    border-color: transparent;
  }
  span {
    margin-left: 5px;
  }
}

.bulk-upload-description-cell {
  white-space: inherit;
}

.upload-header {
  display: flex;
  justify-content: flex-start;
  &-download {
    align-items: center;
    margin-left: 10px;
  }
}

.upload-header-message {
  margin-left: 10px;
}

.downloadBtn {
  margin-left: 20px
}

.icon-add, .icon-upload {
  display: flex;
  align-items: center;
}

.save-row {
  color: #a3c928;
  font-size: 15px!important;
  border: 1px solid transparent!important;
  background: transparent!important;
  padding: 0px!important;
  height: 0px!important;
  justify-content: center;
}

.action-btns {
  align-items: center;
  justify-content: space-around;
  text-align: center;
  display: flex;
}

.page-icon-export {
  display: flex;
  align-items: center;
  height: 30px;
  background-color: @main-yellow-color !important;
  margin-left: 10px;
  border-radius: 50px;
  .anticon-download {
    color: #000 !important;
  }
	&:hover {
		background-color: #ddb65b !important;
		color: #000;
		border-color: transparent;
	}&:focus {
    color: #000;
  }
}

.page-icon-edit {
	background: #72d2e4 !important;
	&:hover {
		background-color: #5ec1d4 !important;
		color: #000;
		border-color: transparent;
	}
}
.page-icon-delete {
	rotate: 90deg;
	background: @main-red-color;
	&:hover {
		background-color: @main-red-color !important;
		color: #000;
		border-color: transparent;
	}
}

// Footer
.gx-layout-footer-content {
  text-align: center;
}

//Save success message

.ant-message {
  bottom: 50px;
  top: 0;
}

// Media Queries

.gx-header-notifications {
  @media @large-screen-down {
    margin-left: auto;
  }
}

.top-filter-popup ul.ant-cascader-menu {
  max-height: 40vh;
}

.top-filter-popup.top-filter-dropdown {
  width: 100%;
}

ul.ant-cascader-menu::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

ul.ant-cascader-menu::-webkit-scrollbar-track {
 border-radius: 10px;
 background: transparent;
}

ul.ant-cascader-menu::-webkit-scrollbar-thumb {
 background: @text-color;
 border-radius: 10px;
}

.rc-virtual-list-holder::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.rc-virtual-list-holder::-webkit-scrollbar-track {
 border-radius: 10px;
 background: transparent;
}

.rc-virtual-list-holder::-webkit-scrollbar-thumb {
 background: @text-color;
 border-radius: 10px;
}

.ant-cascader.ant-select-show-search .anticon.anticon-close-circle {
  background-color: @input-dark-bg;

  svg {
    color: @grey-6;
  }
}

.filterDropdowns {
  .ant-row-flex {
    margin:  0 0 10px 0;
    align-items: center;
  }
  .ant-row-flex.clearBtn {
    margin:  40px 0 10px 0;
  }
}

.ant-form-item-label {
  text-align: left;
  line-height: 17px;
  white-space: normal;
}
.editNameInputRow {
  margin:  0 0 10px 0;
}
@import './tablesCommon.less';

// fix for Form.Item container
.ant-form-item-control-input-content {
  padding-left: 15px;
  padding-right: 15px;
  .ant-input {
    height: 40px;
  }
}
.ant-input.room-bed-name {
  height: 40px;
}

.ant-select-selection-search-input {
  height: 40px;
}

// Menu.ItemGroup
.ant-layout-sider .ant-menu-item-group-title {
  padding-top: 10px;
  margin-left: -5px;
}
.group-item {
  font-size: 16px;
}

.main-menu-item {

  .group-item {
    color: @text-color;

    &:hover {
      color: @white-color;
    }
  }

  .group-item.on {
    color: @white-color;
    .icon {
      margin-right: 20px;
    }
  }
  .group-item.main-menu-title {
    justify-content: space-between;
  }

  img {
    width: 20px;
    margin-right: 20px;
    color: @text-color;
  }

  .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title {
    padding-left: 48px !important;

    &:hover {
      color: @white-color;
    }
    &:active {
      background-color: transparent;
    }
  }

  .ant-menu.ant-menu-sub > .ant-menu-submenu > .ant-menu.ant-menu-sub > .ant-menu-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
    a {
      padding-left: 61px !important;
      display: flow-root;
    }
  }
  .reporting-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline li.ant-menu-item.ant-menu-item-only-child a {
    padding-left: 74px !important;
  }
}

.main-menu-item.collapsed img {
  margin-right: 20px;
}

.ant-drawer-body {
  overflow-y: hidden;
}

.ant-menu-dark .ant-menu-item > a {
  margin-left: 5px;
}

// small fix for clear filters button on dashboard
.dashboard .reset-btn {
  line-height: 17px;
}

.ar-full-layout {
  direction: rtl;

  // fix for icon in language lelector
  .gx-language {
    .flag {
      margin-left: 5px;
    }
  }
  .save-btn-container {
    text-align: left!important;
  }

  // languages list
  .gx-popover-lang-scroll {
    // remove native vertical scroll bar
    > div:first-child {
      overflow-y: auto !important;
    }

    // fix languages list
    .flag.gx-mr-2 {
      margin-left: 0.5rem !important;
      margin-right: 0 !important;
    }

    // fix for japanese flag icon
    .gx-sub-popover {
      margin-right: -5px;
    }
  }

  // top filters
  .listings-top-filter {
    span.ant-cascader-picker:not(:last-child) {
      margin-left: 15px;
      margin-right: 0;
    }
  }

  // sidebar
  .gx-sidebar-content {
    // username field
    .gx-sidebar-notifications {
      .gx-avatar-name .icon.gx-ml-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
      }
    }

    .gx-layout-sider-scrollbar {
      > div:first-child {
        overflow-y: hidden!important;
      }
    }

    // sidebar menu
    li.ant-menu-item-group {
      .ant-menu-item-group-title .group-item .icon {
        margin-right: 0;
        margin-left: 12px;
      }

      // sidebar sub-menu
      ul.ant-menu-item-group-list {
        .ant-menu-submenu-title .icon {
          margin-right: 0;
          margin-left: 20px;
        }
        .ant-menu-item a .icon {
          margin-left: 12px;
        }

        // sidebar submenu with items (open/closed)
        .ant-menu-submenu {
          .ant-menu-submenu-title {
            padding-right: 40px !important;

            .ant-menu-submenu-arrow {
              left: auto;
              right: 22px;
            }

            .icon-extra-components {
              margin-right: 20px;
            }
          }
        }

      }
    }
  }

  // buttons fix
  .page-icon-export {
    margin-left: 0;
    margin-right: 10px;
  }

  // pagination in bottom of list screens (Organization, Customers, etc...)
  .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination {
    float: none;
  }
}
// Style for Japanese language


.ja-full-layout {
  .gx-card .ant-card-head {
    .ant-card-head-title {
      font-size: 1rem;
    }
  }
  .ant-card {
    font-size: 13px;
  }
}

@btn-padding-base: 0 15px;
@btn-padding-lg: 0 15px;
@btn-padding-sm: 0 15px;

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.unit-card {
  margin: 0 auto;
  text-align: center;
}

.ant-row {
  margin-bottom: 10px;
}

.ant-form-item-label {
  padding: 10px !important;
}

.logout-button {
  border: 0;
  padding: 0;
  color: #45a1e5;
  height: 15px;
  display: flex;
  align-items: center;

}
.delete {
  color: #ED67A4;
  display: inline-block;
}

.uhe-table-td {
  display: flex;
  align-items: center;

}

.uhe-table-td a {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete {
  margin-bottom: 0px!important;
}

.domainList .save-icon {
  display: none;
}
.reset-logo-image {
  width: 275px;
}

.reset-password-wrapper {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  .ant-form-item-control-input-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.reset-form {
  width: 100%;
}

.reset-submit-btn {
  width: 33.33%;
  background-color: #007cc2;
  color: @white-color;
}

.reset-submit-btn:hover, .reset-submit-btn:focus {
  background-color: #006ba7;
  color: @white-color;
}

.gx-layout-sider-scrollbar > div:first-child {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  margin-bottom: 0 !important;
}
.gx-layout-sider-scrollbar > div:first-child::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.changePassword .ant-form-item-control-input-content {
  padding-right: 7px!important;
}

.change-confirm-password {
  background-color: #0B2034 !important;
}

.iobserver-tables .ant-table-cell {
  padding-left: 6px !important;
}

@media only screen and (max-device-width: 991px) {
  .gx-avatar-row {
    margin-bottom: 0.3rem !important;
    .gx-avatar-name {
      font-size: 1.02rem;;
      color: #262626;
      margin: -20px;
    }
  }
  .gx-d-block {
    margin-left: -15px;
  }

  .upload-btn {
    font-size: 11.3px;
  }

  .gx-header-notifications > li:not(:last-child) {
    margin-right: 25px;
  }
}

.bedcarts, .reporting {
  font-size: @txt-md!important;
  text-transform: none!important;
}

.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 0 !important;
  a {
    padding-left: 48px!important;
    display: flow-root;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-layout-sider .ant-menu-inline .ant-menu-item {
  padding-left: 38px!important;
}

// For UI in Organization/Edit/Add page //

.ant-form-item-control-input-content {
  padding-left: 0;
}

.align-row-city {
  display: flex;
}

.align-item-city{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  & label {
    display: flex;
    justify-content: flex-start;
  }
}

.align-item-city.counter {
  color: #0092d7;
  .ant-form-item-label > label {
    color: #0092d7;
  }
  .ant-form-item-control-input-content {
    margin-top: 2px;
  }
}

.align-item-city-form{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  & label {
    display: flex;
    justify-content: flex-start;
  }
  display: inline-block;
  margin: 0 0px;
}

.align-item-city-input{
  width: 37%;
}

.align-item-city-select{
  width: 20%;
}

.ant-btn-primary span {
  color: @white-color!important;
}

.nav-slider,
.nav-slider-collapsed {
  position: absolute;
  top: @nav-slider-top;
  z-index: 10;
  right: @nav-slider-right;
}


.gx-sidebar-content .nav-slider,.gx-sidebar-content .nav-slider-collapsed {
  display: none;
}

.gx-sidebar-content:hover .nav-slider,.gx-sidebar-content:hover .nav-slider-collapsed {
  display: initial;
}

.logo-sm {
  margin-right: auto;
}

.track-horizontal {
  background-color: #6eacdd;
  color: #6eacdd;
}

.thumb-vertical {
  background-color: @scrollbar-color;
  color: @scrollbar-color
}

.showVirtualScrollbar .rc-virtual-list-scrollbar {
  display: block !important;
  opacity: 0;
}

.showVirtualScrollbar .rc-virtual-list:hover .rc-virtual-list-scrollbar {
  opacity: 1;
}

.rc-virtual-list-scrollbar-thumb {
  background-color: @scrollbar-color !important;
}

ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none !important;
}

.ant-menu-submenu-arrow {
  display: none !important;
}

.ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none !important;
}

.reporting-submenu {
  padding-left: 0px !important;
}

.uhe-stats {
  margin-left: auto;
}

.reporting-submenu.ant-menu-submenu.ant-menu-submenu-inline  div.ant-menu-submenu-title {
  padding-left: 61px !important;
}

.reporting-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline li.ant-menu-item.ant-menu-item-only-child {
  padding-left: calc(51px + 5px) !important;
  &:hover {
    a {
      color: @white-color;
    }
  }
  a:hover {
    color: @white-color;
  }
}

.reporting-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline li a{
  color: @text-color;
}

.reporting-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline li.ant-menu-item-selected  a{
  color: @main-green-color;
}

.ant-layout-sider {
  overflow: unset;
 .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon {
   font-size: 15px;
 }
}

.gx-sidebar-notifications {
  position: relative;
  border: none;
}

.menu-msg {
  display: initial;
}

.menu-msg-collapsed{
  display: none;
}

.permisssion {
  .ant-card-body {
    padding: 24px!important;
  }
  .ant-col {
    margin-right: 5px;
  }
}

.userManagement .ant-table-cell {
  text-align: center;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: auto!important;
  min-height: unset;
  padding: 0px 11px!important;
}
.permissionRole .ant-form-item {
  align-items: center!important;
}

.permissionRole .ant-form-horizontal .ant-row {
  margin-left: 0px!important;
  margin-right: 0px!important;
}


.my-btn {
  width: 100%;
  background: #44B449 !important;
  color: #FFFFFF !important;
  font-size: 16px;
  border-color: transparent!important;
  height: 40px;
  &:hover {
    background-color: #44E649 !important;
  }
}

.asdasd {
  font-weight: bold!important;
}

.boldedMessage {
  font-weight: bold!important;
  color: red!important;
}

.permission-table {
  width: 100%;
}

.hideReporting{
  display: none;
}
.videos-fields{
  margin: 6px !important;
}

.ant-btn-sm {
  line-height: 18px!important;
}

.ant-popover-buttons .ant-btn-sm:first-child {
  background-color: #c8c8c8;
  color: #646464 !important;
  border-color: transparent !important;
  height: 34px;
  &:hover {
    background-color: #e6e6e6 !important;
  }
  span {
    color: #646464 !important;
    font-size: 16px;
    font-weight: bold;
  }
}

.ant-popover-buttons .ant-btn-sm:nth-child(2) {
  background-color: #0092d7 !important;
  border-color: transparent !important;
  height: 34px;
  &:hover {
    background-color: #49b2ff !important;
  }
  span {
    color: #fff !important;
    font-size: 16px;
    font-weight: bold;
  }
}

.ant-table-selection-column {
  width: 7%;
}

.bulk-actions-table {
  display: flex;
  margin: 20px auto;
  justify-content: center;
}

.ant-table-column-sorters {
  padding: 0;
}

.section-titles {
  .ant-table-column-sorters {
    padding-left: 6px;
  }
}

.table-filter-holder {
  padding: 0 16px 16px 16px;
  .ant-cascader-picker {
    margin-top: 4.1px;
  }
}

.table-cascader-dropdown {
  width: 100%;
}

.ant-cascader-menu {
  border-right: none;
}

.ant-table-thead th.ant-table-column-has-sorters {
  padding-top: 13px;
  padding-bottom: 9px;
}

.form-item-row {
  display: flex;
  align-items: center;
  margin: 0 !important;
  .ant-form-item-label {
    display: flex;
  }
  .ant-form-item-label[class*="ant-col-"] {
    text-align: initial;
  }
}

.bulk-action-page {
  .save-icon {
    display: none!important;
  }
  .uhe-table {
    margin-bottom: 0px;
  }
  .reset-btn {
    display: none;
  }
  .permission-table {
    margin: 10px auto;
    width: 60%;
  }
}

.manage-customer-wrapper .operational-status-report .save-icon {
  background-color: #44B449 !important;
  color: #FFFFFF;
  &:hover {
    background-color: #44E649 !important;
    color: #FFFFFF;
  } 
}

.refresh-btn {
  margin-bottom: 15px;
}

.bulk-action-page {
  .filter-inputs {
    .ant-cascader-picker:first-child {
      display: none;
    }
  }
}

.bulk-action-wrapper {
 margin-left: 4px;
}

.outter-list {
  list-style: initial;
}

.inner-list {
  list-style: revert;
}

.ant-table-cell .icon-data-display {
  color: #ED67A4;
}

.device-icon {
  margin-right: 5px;
}

.table-popover {
  text-align: center;
}

.ant-form-item-label[class*="ant-col-"] {
  text-align: initial!important;
}

.ant-form-horizontal .ant-row {
  margin-left: 0!important;
  margin-right: 0!important;
}

.status-online {
margin-bottom: 17px;
}

.main-row {
  padding-left: 11px;
}

.call-btn {
  margin-top: 10px;
}

.fixed-value-width {
  width: 135px;
  margin-right: 10px;
}

.act-btn {
  height: 18px;
  justify-content: center;
  display: flex;
  align-items: center;
  line-height: 28px;
}

.section-titles {
  white-space: normal;
  font-weight: 400;
  color: #17c0d9;
}

.doc-items {
  .ant-form-item-control-input {
    min-height: 40px;
  }
}

.audit-info:last-child {
  margin-bottom: 0;
}

.notification-message {
  .message {
    white-space: initial;
  }
}

.support-icon {
  font-size: 15px !important;
  margin-right: 12px;
}

.ant-menu-item .iobserver-icon {
  margin-right: 20px;
}

.ant-table-pagination-right {
  justify-content: center;
}

.removeToken {
  height: 40px;
  font-size: 18px;
  text-align: center;
}

.removeToken p {
  margin-top: 20px;
}

.removeToken span {
  color: #6eacdd;
  cursor: pointer;
  text-decoration: underline;
}

.delPicure {
  justify-content: end;
}

.devImg {
  display: flex;
  align-items: center;
  margin-top: 5px!important;
  justify-content: space-between;
}

.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon {
  margin-right: 26px;
}

.anticon-question-circle {
  font-size:  24px!important;
  margin-top: 0px;
}

.ant-popover-content {
    margin: 0 auto!important;
    width: 100%!important;
}

.upload_document {
  width: 100%;
}

.upload-btn {
  width: 100%;
}

.jamf-support .ant-form-item-control-input-content {
  padding-right: 0px!important;
}
.teamviewer-credentials {
  margin-left: 10px;
}

.ant-collapse {
  width: 100%!important;
  margin-bottom: 20px;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.margin-left-8 {
  margin-left: 8px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.ant-upload-list-item-list-type-text:hover .ant-upload-list-item-info {
  background: transparent;
}

.bulkUpload__instructions .ant-form-item-control-input-content > span {
  display: flex !important;
  align-items: center;
}

.bulkUpload__instructions .ant-upload-list-text .ant-upload-span, .ant-upload-list-picture .ant-upload-span {
  display: flex !important;
}

.product-documentation {
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

.upload-box{
  width:100% !important;
}

.overflow-wrap-break-word {
  overflow-wrap: break-word;
}

.inCall-icon {
  color: #070782;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.download-csv-button {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #038fde;
  cursor: pointer;
  display: inline-block!important;
  align-items: center!important;
  justify-content: space-between;
  margin: 0 auto;
}

.download-button {
  border-color: transparent;
  color: #038fde;
}

.download-button:hover {
  background-color: transparent;
  border-color: transparent;
}

.download-icon {
  font-size: 15px;
}

.height-100-percent {
  height: 100%;
}

.ant-table .ant-table-container::before, .ant-table .ant-table-container::after{
  display: none;
}

.timestamps > div {
  padding: 0 20px;
  margin-bottom: 10px;
}

.timestamps > div:last-child {
  margin-bottom: 0px;
}

.horizontal-vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.battery {
  margin-top: 19px;
}

.ant-radio-wrapper {
  white-space: normal !important;
  margin:5px;
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none !important;
}

.time-zone {
  width: 37%;
}

.organization-row {
  padding: 0!important;
  .ant-row {
    margin-bottom: 0;
  }
}

.commands-number-title {
  font-size: @txt-3lg;
}

.commands-number {
  color: #0092d7;
}

.filter-title {
  color: #0092d7;
}
.connectivity-icon {
  width: 15px;
  height: 15px;
  margin: 0 !important;
}

.connectivity-icon-fail {
  color: @main-red-color;
}

.connectivity-icon-success {
  color: @main-green-color;
}

.console-btn {
  height: 36px;
}
.numberColumns {
  padding-left: 8px!important;
}

.device-header {
  margin-top: 20px;
}
.viewButton {
  width: 100px!important;
  padding: 10px 20px;
  border-radius: 3px;
  margin-left: 0px;
}

.display-name-container {
  display: flex;
  align-items: center;
  max-width: 220px;
}

.display-name {
  max-width: 210px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.action-button button {
  width: 100%;
  height: 100%;
}

.password-btn-wrapper {
  padding-left: 20px;
}

.button-password {
  align-items: center;
}

.button-password i {
  padding: 5px;
.command-btn--green,
.command-btn--green:hover,
.command-btn--green:focus {
  background: @secondary-color;
  color: #545454;
  border: none;
}
}
.command-btn.command-btn--green {
  background: #0092D7;
  color: #FFFFFF;
  font-size: 16px;
  border: none;
  font-weight: bold;
  &[disabled] {
    background-color: #003A56;
    color: #666666
  }
  &:hover {
    background-color: #49B2FF;
  }
}
.button-password.change-password {
  background-color: #c8c8c8;
  color: #646464;
  font-size: 16px;
  border: none;
  &:hover {
    background-color: #E6E6E6;
  }
}

.show-IP {
  display: flex;
  align-items: center;
}

.bulk-actions-card {
  border: none;
  .ant-card-body {
    padding-left: 10px;
    padding-right: 10px;
    overflow: auto;
    .bulk-actions-selection-table .ant-table-tbody .ant-table-cell {
      padding-left: 22px !important;
      background-color: transparent;
      &.device-id-col {
        padding-left: 24px !important;

      }
    }
    .bulk-actions-selection-table .ant-table-tbody .ant-table-cell:last-child {
      text-align: left !important;
    }
    .bulk-actions-selection-table .ant-table-thead .ant-table-selection-column {
      padding-left: 22px !important;
    }
  }
}

.verify-IP {
  display: flex;
  flex-direction: row;
}

.custom-mobile-patient-wrapper {
  margin-top: 10px;
}

.restartMessage {
  font-weight: 550;
  font-size: 15px;
}

#pie-chart-holder,
#map-filter-holder, .ant-card-body {
  background: #09365A !important;
  .ant-card-head {
    background: #09365A !important;
  }
}

.ant-input-lg {
  font-size: 14px;
}

@media (max-width:470px) {
  .verify-button {
    font-size: 12px !important;
  }
  .console-btn {
    font-size: 11px !important;
  }
}

@media (max-width: 1521px) {
  .verify-IP {
    margin-top: 10px;
  }
}

@media (max-width: 507px) {
  .bulk-upload-csv-input {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 767px) {
  .iobserver-add-row-col {
    margin-top: 10px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 1366px) {
  .display-name-container {
    max-width: 180px;
  }

  .display-name {
    max-width: 170px;
  }
}

@media (max-width: 991px) {
  .gx-avatar-name {
    margin: 0 !important;
  }
  .gx-avatar-row {
    margin-bottom: 0 !important;
  }
  .connectivity-icon {
    margin-top: 7px !important;
  }
  .verify-button {
    height: 50px;
    font-size: 20px;
  }
}

@media (max-width: 1176px) {
  .console-btn {
    height: 45px !important;
    font-size: 11px;
  }
  .verify-button {
    font-size: 12px;
    height: 45px;
  }
}

@media (min-width: 1176px) {
  .connectivity-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1000px) {
  .cil-logo img {
    width: 207px !important;
  }
}

@media (max-width: 575px)  {
  .align-button-row{
    margin-bottom: 0 !important;
  }
  .gx-layout-sider-header {
    padding: 6px 30px 6px 70px;
    .cil-logo {
      height: 100%;
      img {
        height: 100%;
        justify-content: center;
      }
    }

  }
}

@media (max-width: 575px) {
  .reset-button {
    margin-left: 8px !important;
  }
}

.ant-table-column-sorters {
  width: 100%;
}

.ant-table-column-sorters > span {
  width: 100%;
}

.ant-table-column-sorters > span > .table-filter-holder {
  width: 100%;
}

.ant-table-column-sorters > span > .table-filter-holder > div {
  max-width: 250px;
  min-width: 85px;
}

.uhe-table-head {
  justify-content: flex-start;
}

.uhe-table-head > span {
  margin-left: 10px;
}

.verify-button-wrapper .verify-button, .console-btn-wrapper .console-btn {
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  background-color: #44B449 !important;
  color: #FFFFFF;
  border: none;
  &[disabled] {
    background-color: #1B481D !important;
    color: #666666 !important;
  }
  &:not([disabled]):hover {
    background-color: #44E649 !important;
  }
}

.console-btn {
  display: grid;
  text-align: center;
}

.verify-button {
  padding-top: 0 !important;
  display: grid;
  text-align: center;
}

.verify-button-wrapper {
  width: 100%;
  padding-right: 7px;
}

.console-btn-wrapper {
  padding-right: 15px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.columns-list-container {
  background: #fff;
  display: block;
  padding: 20px;
}

.column-item {
  margin-bottom: 10px;
  justify-content: space-around;
}

.columns-menu {
  width: 300px;
  height: 300px;
  justify-content: center;
  background: #fff;
  padding: 15px;
  align-items: center;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
}
.pendingDevices {
  display: inline-block;
  margin-right: 5px;
}

.device-list-container {
  display: inline-block;
}

.device-list {
  margin-right: 5px;
}

.status-device-icon {
  margin-top: -5px;
}

.bulk-action-delete {
  border-color: transparent;
}

.device-delete-btn {
  padding: 0;
  background-color: transparent;
  display: inline-block !important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.date-field {
  align-items: center;
}
.uploadButton {
  width: 100%;
}

.searchInput {
  height: 40px;
}

/* Re-style changes */

.gx-layout-sider-dark {
  .gx-layout-sider-header {
    display: flex;
    background: @body-background;
    img {
      height: 60px;
      justify-content: center;
    }
  }
}

.gx-layout-sider-header {
  box-shadow: none;
}

.ant-layout-sider-children .gx-layout-sider-header {
  padding: 0px;
}

/* Collapse menu */

.gx-app-sidebar.gx-mini-sidebar .ant-layout-sider-children .gx-layout-sider-header {
  padding: 0 5px;
  img {
    height: auto;
  }
}

.uhe-table {
  background: transparent;
  margin-bottom: 5rem;
  border-radius: 6px;
}

.uhe-table-head,
.ant-table-column-title {
  font-size: @font-size-base;

  white-space: nowrap;
  font-weight: @txt-normal;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .icon,
  .ant-table .ant-table-tbody td {
    font-size: @txt-md;
  }

  .ant-input, .ant-input-affix-wrapper {
    background-color: transparent!important;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: @white-color !important;
  }

  #uhe-title {
    font-size: @txt-2xl;
  }

  .ant-card-head-title {
    color: @primary-title;
  }

  .filter-btn {
    .reset-btn {
      color: #000;
      font-size: 15px;
      margin: auto;
    }
  }

  .ant-form-item {
    margin-bottom: 5px!important;
  }

  .ant-form-horizontal .ant-row {
    margin-bottom: 5px!important;
  }

  .sort-icon.icon {
    font-size: 12px;
    min-width: 12px;
    display: inline-block;
  }
}

.ant-table {
  background-color: transparent !important;
}

.ant-table-thead > tr > th:hover,
.ant-table-thead > tr > th {
  color: #0092D7;
  font-size: 18px;
  font-weight: normal;
  background-color: transparent !important;
  border-right: 1px solid transparent;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.userPrograms .ant-table > .ant-table-container > .ant-table-content > table > thead > tr > th {
  border-top: 0px solid transparent;
  border-bottom: @main-border-separator;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
}

.ant-table.ant-table-bordered > .ant-table-container,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.userPrograms .ant-table > .ant-table-container,
.userPrograms .ant-table > .ant-table-container > .ant-table-content > table > tbody > tr > td {
  border-top: 0px solid transparent;
  border-bottom: @main-table-border;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
}

.ant-card-head {
  background: @nav-dark-bg !important;
  padding: 0 10px!important;
}

.table-input {
  background-color: #0A2135;
  border-color: transparent;
  height: 40px;
  .ant-input {
    background-color: #0A2135;
    border-color: transparent;
    min-width: 10px;
  }
}

.ant-picker.ant-picker-range {
  background-color: #0A2135;
  border-color: transparent;
  height: 40px;
  margin-top: 3px;
  .ant-picker-range-separator .ant-picker-separator,
  .ant-picker-suffix {
    color: @text-color;
  }
}

.ant-form-item-label > label {
  color: @label-text-color;
}

/*
inputs, selects, cascaders style
*/
.ant-cascader-picker, .ant-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-upload.ant-upload-select-picture-card,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input[disabled],
.colorPickerWrapper span {
  background: @input-dark-bg;
  border-color: transparent !important;
  color: @text-color;
  &::placeholder {
    color: #7d7d7d;
  }
}
.ant-cascader-picker-focused {
  color: #0092D7 !important;
}
.ant-input[disabled],
.ant-cascader-picker-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  opacity: 0.7;
}
.ant-select-multiple .ant-select-selector {
  padding: 0;
  border: none !important;
  .ant-select-selection-search {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.ant-checkbox-inner, .ant-radio-inner, .ant-switch {
  background: @nav-dark-bg;
  border: 1px solid @text-color;
}

.ant-switch-checked {
  &:focus {
    box-shadow: none;
  }
  .ant-switch-handle {
    top: 2px
  }
}

.ant-switch-handle {
  top: 1px;
}

.ant-switch-checked {
  background-color: @primary-color;
  border: none;
}

.ant-select-arrow {
  color: @text-color;
}

.ant-select-selection-placeholder {
  color: #7D7D7D;
}

.ant-menu-item-group-title:visited {
  background: red;
}

.ant-table-column-title .ant-input-affix-wrapper {
  background-color: @input-dark-bg !important;
  border-color: transparent;
  padding: 0 11px;
  min-width: 100px;
}

.ant-input-affix-wrapper:hover {
  border-color: transparent;
}

.ant-form-item-has-error .ant-input {
  background-color: @input-dark-bg;
  border-color: @lite-theme-red-icon !important;
  &:hover{
    background-color: @input-dark-bg;

  }
}

.ant-input-password-icon {
  color: @text-color;
  &:hover{
    color: @text-color;
  }
}

.ant-input-clear-icon{
  color: @text-color;
}

.ant-select-dropdown {
  background-color: @pie-chart-bg;
  .ant-select-item-option {
    background-color: inherit;
    color: @text-color;

    &:hover {
      color: @primary-color
    }
  }
}

.ant-cascader-menu-item {
  color: @text-color;
  &-active:not(.ant-cascader-menu-item-disabled) {
    color: #0092D7;
    background-color: transparent;
  }
  &:hover{
    color: #0092D7;
    background-color: @nav-dark-bg !important;
  }
}

.delete-icon {
  background-color: transparent;
}

.delete-icon:hover {
  background-color: transparent;
}

.ant-pagination-item {
  background: @input-dark-bg;
  a {
    color: @hor-nav-text-color;
  }
  :hover {
    color: @hor-nav-text-color;
  }
}

.ant-pagination-item-active {
  background-color: @nav-dark-text-color;
  a {
    color: @hor-nav-text-color;
  }
  :hover {
    color: @hor-nav-text-color;
  }
}

.ant-pagination-disabled .ant-pagination-item-link {
  background-color: @input-dark-bg;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  background-color: @input-dark-bg;
}

.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: #083659;
}

.gx-linebar {
  color: #0092D7;
}

.ant-btn-circle {
  background-color: #0386c2 !important;
  color: #fff !important;
  :hover {
    background-color: #0386c2 !important;
  }
}

.profile-wrapper .separator-line {
  margin-right: 5px;
  margin-left: 20px;
  height: 50px;
  border-right: 1px solid #C8C8C8;
}

.ant-popover {
  &-arrow {
    border-color: @body-background !important;
    .ant-popover-arrow-content {
      &:before {
        background: @body-background;
      }
    }
  }
  &-title {
    background-color: @body-background;
    border-bottom-color: #7d7d7d;
    h1 {
      color: #0092d7;
    }
  }

  &-inner-content {
    color: @text-color;
    background-color: @body-background;
    h3 {
      color: #0092d7;
    }
  }
}

.ant-table-thead > tr > .ant-table-cell {
  vertical-align: baseline;
}

.dark-mode-modal {
  background-color: rgba(12, 42, 68, .9);
  padding-bottom: 0;
  color: #0092D7;
  border-radius: 10px;
  -webkit-box-shadow:0px 0px 5px 0px rgba(0,0,0,0.7);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.7);

  .ant-modal-confirm-title {
    color: #0092D7;
  }

  .ant-modal-content {
    background-color: transparent !important;
  }

  .ant-btn {
    background-color: #c8c8c8;
    border-color: transparent;
    color: #646464;
    font-size: 16px;
    font-weight: bold;
    &:hover {
      background-color: #e6e6e6;
    }
  }

  .ant-btn-primary {
    background-color: #0092d7 !important;
    color: #fff;
    &:hover {
      background-color: #49b2ff !important;
    }
  }

  .ant-modal-footer {
    border-top-color: #7D7D7D;
    .ant-btn {
      background-color: #c8c8c8;
      font-size: 16px;
      font-weight: bold;
      border: none;
      &:hover {
        background-color: #E6E6E6;
      }
      span {
        color: #646464;
      }
    }
    .ant-btn.ant-btn-primary {
      background-color: #0092D7 !important;
      font-size: 16px;
      font-weight: bold;
      border: none;
      &:hover {
        background-color: #49B2FF !important;
      }
      span {
        color: #FFFFFF;
      }
    }
  }
}

.app-selector, .cil-profile {
  .ant-select-selection-item {
    color: #0092D7;
  }
}

.cil-profile {
  .ant-avatar-circle {
    margin: 0 !important;
    width: 45px !important;
    height: 45px !important;
    .ant-avatar-string {
      top: 14% !important;
    }
  }
  .profile-dropdown {
    .ant-select-selector {
      margin-top: 3px;
      padding-left: 5px !important;
    }
    .ant-select-selection-item {
      font-size: 1.29rem !important;
    }
    .ant-select-arrow {
      margin-top: -7px;
      .anticon svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}

.profile-dropdown.ant-select-single.ant-select-show-arrow {
  width: fit-content;
}

.app-selector {
  .ant-select-selection-item {
    font-size: 2rem;
  }
}

.cil-profile .ant-select:not(.ant-select-customize-input) .ant-select-selection-item {
  color: #0092D7 !important;
}

.customer-edit-attributes-card {
  .ant-card-body .baseLineAlignedItems {
    .ant-col {
      overflow-wrap: break-word;
    }
  }
}

.ant-select-single.ant-select-show-arrow  {
  .ant-select-selection-placeholder, .ant-select-selection-item{
    line-height: 38px;
  }
}

@media (max-width: 1150px) {
  .profile-wrapper {
    .profile {
      .avatar {
        width: 25px;
        height: 25px;
        .ant-avatar-string {
          top: 0;
        }
      }
    }
    .logout-btn {
      .ant-avatar {
        width: 22px !important;
      }
    }
  }

  .topbar-title {
    font-size: 1.443rem;
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0092D8 !important;
}

.ant-menu-item-selected {
  color: @white-color;
  a {
    color: @white-color;
  }
}

.ant-menu-item:hover {
  color: @white-color !important;
  a {
    color: @white-color;
  }
  span {
    color: @white-color !important;
  }
}

.ant-menu-item-selected {
  color: @white-color !important;
  :hover {
    color: @white-color !important;
  }
  span {
    color: @white-color !important;
  }
}

.ant-menu-item-selected, .ant-menu-item:active {
  background-color: #0092D8 !important;
  span {
    color: @white-color !important;
  }
}

.ant-menu-submenu > .ant-menu {
  background-color: transparent;
}

.gx-customizer-item {
  text-align: center;
  display: none;
}

.bulk-actions-queue .ant-table-tbody .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header{
  padding-left: 0;
  padding-top: 12px;
  color: @text-color;
}

.bulk-actions-queue .ant-table-tbody td {
  border-color: @input-dark-bg;
}

@media (max-width: 991px) {
  .top-header {
    justify-content: normal;
  }

  .profile-wrapper {
    margin-left: auto;
  }
}

@media (max-width: 440px) {
  .profile-wrapper {
    .profile {
      .ant-avatar {
        display: none;
      }
      .ant-select-selection-item {
        font-size: 1.25rem;
      }
    }
  }

  .app-selector {
    .ant-select-selection-item {
      font-size: 1.25rem;
    }
  }
}

.table-footer {
  text-align-last: center;
}

.table-footer .ant-empty-normal {
  border-bottom: 4px solid #0B2034;
  padding-bottom: 32px;
}

.ant-radio-button-wrapper {
  background: transparent;
}

.lite-theme .ant-radio-button-wrapper {
  background-color: transparent !important;
  color: #fff;
  :hover {
    color: #fff;
  }
}

.lite-theme .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: transparent;
  color: #0092D7;
  border-color: #0092D7;
}

.manage-customer-wrapper button[title="Copy MDM Token"]{
  background-color: #0092D7;
  font-size: 16px;
  border: none;
  color: #FFFFFF;
  &:hover {
    background-color: #49B2FF;
  }
}

@media screen and (min-width: 992px) and (max-width: 1085px) {
  .app-selector, .cil-profile {
    .dropdown > .ant-select-selector {
      font-size: 1.2rem
    }
  }

  .topbar-title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 577px) {
  .manage-customer-wrapper {
    .ant-col {
      width: 100%;
    }
  }
}

@media screen and (max-width: 571px) {
  .app-selector {
    .ant-select-selection-item {
      font-size: 1rem;
    }
  }

  .cil-profile {
    .dropdown > .ant-select-selector {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 498px) {
  .app-selector {
    .ant-select-selection-item {
      font-size: 0.8rem;
    }
  }

  .cil-profile {
    .dropdown > .ant-select-selector {
      font-size: 0.8rem;
    }
  }
}

@media screen and (max-width: 399px) {
  .cil-profile {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding: 0 !important;
    }
  }

  .top-header .dropdown .ant-select-selection-item {
    padding: 0;
  }

  .top-header {
    padding: 0 5px;

    .dropdown > .ant-select-arrow > .anticon-down svg {
      width: 15px;
      height: 15px;
    }
  }
}

@media screen and (max-width: 390px) {
  .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    min-width: 20px;
  }
}

@media screen and (max-width: 361px) {
  .app-selector {
    .ant-select-selection-item {
      font-size: 0.6rem;
    }
  }

  .cil-profile {
    .dropdown > .ant-select-selector {
      font-size: 0.6rem;
    }
  }
}

@media screen and (max-width: 326px) {
  .logout-btn {
    img {
      width: 85%;
    }
  }

  .cil-profile {
    align-items: center;

    .avatar {
      height: 30px !important;
      width: 30px !important;
      margin-right: 5px !important;
    }

    .ant-avatar-string {
      top: 4% !important;
    }
  }
}

@media screen and (max-width: 300px) {
  .top-header {
    .ant-select-selector {
      width: 80px;
    }

    .cil-profile {
      width: 115px;
    }
  }
}

.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: #083659;
  color: #fff;
  a {
    color: #fff;
  }
}

.top-header .dropdown > .ant-select-arrow > .anticon-down svg {
  width: 14px;
}

.top-header-divider {
  width: 97%;
  margin: 0 auto;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #0092D8 !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:hover {
  background-color: #0092D8 !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item {
  color: @white-color;
}

.header-divider {
  border-color: #7d7d7d;
  width: 94.1%;
  margin: 0 auto;
  margin-left: 40px;
}

.ant-avatar {
  margin: 0 20px;
}

.gx-main-content-wrapper {
  margin: 0 15px 0 25px;
  width: 96%;
}

.view-container {
  align-items: center;
  border-top: @main-border-separator;
  border-bottom: @main-border-separator;
  padding: 15px 0;
  width: 97%;
  margin: 0 auto;
  p, button {
    margin-bottom: 0px;
  }
}

.selectedChart {
  color: #0092D7 !important;
}

.unSelectedChart {
  color: @label-text-color !important;
}

.dashboard-container {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.uhe-table-td a {
  color: #cbcbcb;
}

.chart-text-container {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  height: 35px;
  padding-bottom: 10px;
}

.chart-title {
  margin-right: 20px;
  font-size: 24px;
  margin-bottom: 0px;
}

.chart-icon {
  margin-right: 3px;
}
.down-outlined {
  margin-left: 3px;
}

.anticon-pie-chart {
  font-size: 18px;
}

.ant-cascader-picker-arrow {
  color: @label-text-color;
}

.ant-cascader-menus {
  background-color: #09365A;
}

td.ant-table-column-sort {
  background: inherit;
}

.ant-cascader-menu-item:hover {
  background: @body-background;
}

.ant-typography.support-title {
  color: #0092D7;
}

.customer-edit-branding-card {
  .ant-card-head, .ant-card-body {
    background-color: #0D304C !important;
  }

  .ant-collapse {
    &-header {
      background-color: #09365A;
      color: #0092D7 !important;
    }

    &-arrow {
      color: #c8c8c8 !important;
    }

    &-item {
      border-color: #808080;
    }

    &-content {
      border-color: #808080;
      border-left: 1px solid #808080;
      border-right: 1px solid #808080;
      border-radius: 0 !important;

      &-box {
        background-color: #0D304C;
      }
    }
  }

  .document-link {
    background-color: inherit;
  }

  .support-delete {
    background-color: #FE0000;
    border: 0;
  }

  .ant-collapse {
    border: 0;

    &-header {
      border-radius: 0 !important;
    }
  }
}
.pac-container {
  background-color: @menu-dark-bg;
  .pac-item:hover {
    background: none;
  }
  span {
    color: @label-text-color
  }
}
.ant-dropdown-menu {
  background-color: @menu-dark-bg;
}


.gx-sidebar-content {
  height: 100%;
  position: relative;
  z-index: 2;
  -webkit-box-shadow:0px 0px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  border: 0;
}

/* Show more table */
.ant-table-wrapper.custom-scrollbar .ant-table {
  overflow-x: auto;
  padding-right: 5px;
}

.ant-table-wrapper.custom-scrollbar.custom-scrollbar-hidden .ant-table {
  overflow-x: hidden!important;
}
/* width */
.ant-table-wrapper.custom-scrollbar ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
.ant-table-wrapper.custom-scrollbar ::-webkit-scrollbar-track {
 border-radius: 10px;
 background: @input-dark-bg;
}

.ant-table-wrapper.custom-scrollbar ::-webkit-scrollbar-thumb {
 background: @text-color;
 border-radius: 10px;
}
.ant-table-wrapper.custom-scrollbar ::-webkit-scrollbar-corner {
  background: transparent;
}

.ant-table-wrapper .ant-spin-container .ant-table-content table {
  border-top: none !important;
}

.scrollable-table {
  overflow-y: auto;
  max-height: 600px;
  padding-right: 20px;

  .gx-table-responsive {
    overflow-x: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: @input-dark-bg;
    }

    &::-webkit-scrollbar-thumb {
      background-color: @text-color;
      border-radius: 10px;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: @input-dark-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @text-color;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.gx-sidebar-content {
  height: 100% !important;
  .gx-layout-sider-scrollbar {
    height: calc(100vh - 185px) !important;
  }
}

.reporting-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline li.ant-menu-item-selected a {
  color: #fff;
}

.permission-table {
  .action-btns {
    justify-content: flex-start;
  }
}

.ant-select-show-search.ant-select:not(.ant-select-disabled) .ant-select-selector:hover {
  cursor: pointer;
}

.ant-notification-success,
.ant-notification-error,
.ant-notification-copy-success {
  background-color: @pop-up-dark-bg;
  border-radius: @border-radius-lg;
  opacity: 0.9;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.7);
  .ant-notification-notice-message {
    color: #FFFFFF;
  }
  .ant-notification-notice-close {
    color: #0092D7;
    vertical-align: middle;
  }
  img {
    width: 30px;
    margin-right: 0;
  }
}

//react-notifications
.notification {
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.7);
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.7);
  border-radius: @border-radius-lg !important;
}

.notification-success {
  background-color: @pop-up-dark-bg;
  opacity: 0.9;
  &::before {
    content: url('~assets/images/dialog_check.svg');
    top: 35%;
  }
}

.notification-error {
  background-color: @pop-up-dark-bg;
  opacity: 0.9;
  &::before {
    content: url('~assets/images/dialog_warning.svg');
    top: 35%;
  }
}

.side-panel-title .uhe-table-td {
  padding-left: 0px;
}

.ant-cascader-menu .ant-cascader-menu-item-active {
  background-color: transparent;
}

.label-type, .label-id {
  margin-right: 5px;
}

//actions column fix width
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:last-child {
  width: 90px;
  text-align: center;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background: @input-dark-bg;
}

.align-numeric-cell {
  text-align: right;
}

.ant-menu-item button {
  width: 25px;
  .ant-btn span:first-child {
    color: #c8c8c8 !important;
  }
}

.ant-empty-description {
  color: #c8c8c8;
}

.ant-table-placeholder {
 background-color: transparent;
 :hover {
  background: transparent !important;
 }
}

.gx-card.pie-chart-card .ant-card-head-title {
  margin-top: 15px;
  margin-left: 15px;
}

.recharts-text tspan {
  font-size: 20px;
}

.main-component {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: @input-dark-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @text-color;
    border-radius: 10px;
  }
}

.main-menu-title span,
.ant-menu-item a {
  font-family: @main-menu-font-family;
}

.apd-table td.ant-table-cell{
  padding-left: 16px;
  .uhe-table-td {
    padding-left: 0px;
  }
}

.ant-table-cell {
  height: 42px;
}

.table-info .ant-select-selector {
  margin-top: 9px;
}

.customize-column-arrow {
  margin-left: 5px;
  font-size: 14px !important;
}

.selected-devices-card .ant-card-head-title {
  padding-bottom: 0px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.total-devices-select .ant-select-selection-item {
  margin-top: -5px;
}

.ant-menu-vertical .ant-menu-item {
  font-size: 16px;
}

.tooltip-collapsed {
  margin-left: 235px;
}

//collapsed menu width
.ant-layout-sider-collapsed {
  width: 70px !important;
}

.reporting-submenu .group-item i {
  margin-right: 15px !important;
}

.monitoring-aps-no-selected-column {
  font-size: 18px;
  color: @text-color;
  text-align: center;
  width: 100%;
  margin-top: 100px;
}

.gx-card.advanced-dev {
  background: transparent !important;
  box-shadow: none;
  .ant-card-head,
  .ant-card-body {
    background: transparent !important;
  }

  .table-filter-holder {
    padding-left: 0;
  }
}

.table-date-picker-dropdown .ant-picker-panel-container {
  background-color: @nav-dark-bg;
  .ant-picker-panel {
    border: none;
  }
}

@media(max-width: 767px) {
  .uhe-table .gx-table-responsive .ant-table {
    border: none;
  }
}