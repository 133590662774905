@import "../../ui/uhe/uheVariables";
@import "../../ui/uhe/uheMixins.less";

.ant-form-item-label[class*="ant-col-"] {
  text-align: center;
}

.status-online {
  [class*="ant-col-"]:nth-child(2) {
    display: flex;
    align-items: center;
  }
}

.add-new-card {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

.add-bed-cart .add-new-card .ant-form-item {
  margin-bottom: 2.1rem !important;
  .ant-form-item-label {
    padding: 0 10px !important;
  }
}

.vertical-sidebar {
  border-left: 4px solid #17c0d9;
  padding-left: 10px;
}

.device-attr {
  label {
    width: 100%;
    overflow: hidden;
  }
}

.device-attr-row {
  padding: 0 10px;
}

.device-attr-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.care-device-row {
  margin: 0 !important;
}

.call-room-btn {
  .call-room-icon {
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #545454 !important;
    transition: .3s;
  }
  &:hover {
    .call-room-icon {
      color: #28aaeb !important;
    }
  }
}

.status-icon-green {
  .deviceStatus(@main-green-color, rotate(0deg), 0% );
}

.status-icon-red {
  .deviceStatus(@main-red-color, rotate(0deg), 50%);
}

.status-icon-grey {
  .deviceStatus(#aaa2a2 , rotate(45deg), 0%);
}

.status-icon-blue {
  .deviceStatus(#0092D7, rotate(0deg), 50%)
}

.acknowledged-email {
  word-wrap: break-word;
}

.fac-unit-names {
  color: #8d8d8d;
  font-size: 14px; 
  margin-bottom: 20px; 
}