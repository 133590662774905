/**
  Edit Pages Header Styles
*/

.manage-unit-wrapper, .manage-customer-wrapper, .manage-ssoProvider-wrapper {
  .uhe-icon {
    font-size: 1.1rem;
    padding: 10px;
    border-radius: 50%;
    background: @secondary-color;
    cursor: pointer;
    margin-right: 8px;
  }

  .uhe-icon-plus {
    background: @secondary-color;
  }

  .uhe-icon-edit {
    background: #a0d8ec;
  }
  
  .uhe-icon-delete {
    background: @main-red-color;
  }

  .lite-theme .uhe-icon-delete {
    background: @lite-theme-main-red-color;
  }

  

  .ant-btn > i {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-btn-circle.ant-btn-lg {
    margin-bottom: 0;
  }

  .config-icon {
    color: #000;
    font-size: 1.1rem;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 8px;
  }

  .back-btn {
    background-color: @main-back-button-color !important;
    color: #646464;
    &:hover {
      background-color: #E6E6E6 !important;
      color: #646464;
      border-color: transparent;
    }
    &:focus {
      border-color: transparent;
      background-color: #E6E6E6;
    }
  }

  .save-btn {
    background-color: @main-save-button-color !important;
    color: #fff;
    &:hover {
      background-color: #44E649 !important;
      color: #fff;
      border-color: transparent;
    }
    &:focus {
      border-color: transparent;
      background-color: #44E649;
    }
  }
  .prev-icon {
    background: @grey-btn;
    &:hover {
      background: @grey-btn-darker;
      color: #000;
      border-color: transparent;
    };
  }
}

.header-btn {
  align-items: center;
  width: 100px;
  height: 40px;
  color: #000;
  font-weight: bold;
  border-color: transparent;
  font-size: 16px;
}

.cancel-btn {
  background-color: @main-cancel-button-color;
  color: #fff;
  &:hover {
    background-color: #FF4B00 !important;
    color: #fff;
    border-color: transparent;
  }
  &:focus {
    border-color: transparent;
    background-color: @main-red-color;
  }
}






