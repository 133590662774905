@import '../../ui/uhe/uheVariables';
@import '../../ui/uhe/uheMixins.less';
@import '../../pages/uhe/manageOrganization.less';
@import '../../pages/uhe/manageCustomers.less';
@import '../../pages/uhe/manageUsers.less';
@import '../../pages/uhe/systemSettingsSupport';
@import '../../pages/uhe/bulkUpload';
@import './notifications.less';

// ~~~~~~~~~~~~~ Top Filters ~~~~~~~~~~~~~
.filter-inputs {
  @media @phone-down {
    .ant-cascader-picker:not(:last-child) {
      margin: 0 0 15px 0;
    }
    flex-direction: column;
  }
}
// ~~~~~~~~~~~~~ Pie Chart ~~~~~~~~~~~~~
.pie-chart-box {
  background-color: @pie-chart-bg;
  .ant-card-body {
    padding-top: 10px;
    .pie-chart-flex {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .pie-chart-info {
        display: flex;
        flex-direction: row;

        ul {
          padding: 0;
          .pie-chart-dot {
            font-size: 22px;
          }
        }
      }

      @media @wide-screen-down {
        flex-direction: column;
      }
    }
  }
}

.chart-map-selection {
  display: flex;
}

.map-col {
  flex: 1;
  margin-left: 10px; 
}

.chart-col {
  flex: 0 0 550px; 
}

.recharts-layer {
	cursor:pointer;
}

.no-pie-data {
  margin: 80px auto;
}

#pie-chart-holder,
#map-filter-holder {
  height: 370px;
}

#map-filter-holder {
  .ant-card-head {
    padding-top: 13px !important;
  }
  .ant-card-head-title {
    font-size: 16px;
    span {
      padding-right: 5px;
    }
  }

  .ant-card-body {
    border-radius: 10px;
  }
}

table.fixed {
  table-layout: fixed;
}
td.truncated {
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
  text-overflow: ellipsis;
}
#map-filter-holder,
.ant-card-body {
  background-color: @map-bg;
}

#map-filter-holder   {
  padding: 0 10px 10px 10px;
  .map-title {
    font-size: 16px;
    vertical-align: bottom;
    display: inline;
  }
}

.map-chart-icon {
  display: inline-block;
  height: 17px;
  width: 17px;
  margin-right: 5px
}

.gx-card .ant-card-head-title {
  padding-top: 0px;
}

.gx-card .ant-card-head {
  padding: 0 10px;
}

.gx-card-body,
.gx-card .ant-card-body {
  padding: 10px 10px 0px 10px;
}

.modal__icon {
  font-size: 22px;
}

.modal__icon.modal__icon--warning {
  color: #fa8c16 !important;
}

.modal__title {
  font-size: 16px;
  font-weight: 500;
}

.gx-card.pie-chart-card {
  width: 260px;
  height: 175px;
  margin:0 20px 20px 0;
  background: @nav-dark-bg !important;
  border: 2px solid @nav-dark-bg;

  &:hover{
    border: 2px solid @pie-border;
  }
  
  .ant-card-body {
    height: 150px;
    padding: 0;
  }

  .ant-card-head-title {
    font-size: 16px;
    padding-top: 5px;
  }
  
}

.dropdown-select {
  display: flex;
  flex-direction: row;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-left: 0;
  width: fit-content;
  .ant-select-selection-search{
    width: fit-content;
  }
}

.dropdown-select > .ant-select-selector {
  color: @pie-border;
  font-size: 18px;
  width: fit-content;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
}

.dropdown-select > .ant-select-arrow {
  left: auto;
  padding-left: 15px !important;
  color: @text-color;
  background-color: transparent;
}

.ant-select-open > .ant-select-arrow > .anticon-down  {
  transform: rotate(180deg);
}

.dropdown-select .ant-select-selection-item {
  font-size: 18px;
  padding: 0 !important;
  color: @text-color;
}

.dropdown-select.ant-select-open .ant-select-selection-item {
  color: #0092d7 !important;
}

.ant-select-dropdown {
  background-color: @pie-chart-bg;
  
  .ant-select-item-option {
    background-color: inherit;
    color: #bfbfbf;

    &:hover {
      color: @pie-border
    }
  }

  .ant-select-item-option.ant-select-item-option-selected {
    color: #0092d7;
  }
}

.dropdown-select .ant-select-selector {
  padding: 0 !important;

  &::after {
    margin-left: 10px;
  }
}

.dashboard .ant-collapse {
  background: inherit;
  border: none!important;
  margin-bottom: 0;
  .ant-collapse-item:last-child{
    border: none;
  }
}

.dashboard .ant-collapse-content{
  border: none;
  background: inherit;
  .ant-collapse-content-box {
    padding-bottom: 0;
  }
}
.pie-chart-label tspan {
  color: white;
}

.pie-drop-down {
  width: auto;
  margin-bottom: 3px;
}

.dropdown-title {
  color: @pie-border;
  font-size: 18px;
}
.pie-chart-wrap {
  display: flex;
  justify-content: center;
  margin-left: 0;
}

.pie-wrap{
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: fit-content;
  min-height: fit-content;
}

.pie-wrap .gx-card.pie-chart-card{
  overflow: hidden;
}

.selected-pie {
  border: 2px solid @pie-border !important;
}

.pie-icon {
  margin-left: 5px;
  width: 20px;
}

.status-icon {
  width: 13px;
}

.version-content span img.status-icon {
  margin-left: 4px;
  margin-bottom: 3px;
}

.table-info {
  height: auto;
  min-height: 55px;
  border-top: @main-border-separator;
  .gx-mr-3 {
    padding-bottom: 0;
    padding-top: 12px;
  }
}

.table-info .gx-mr-3 {
  padding-bottom: 3px;
  padding-top: 17px;
}

.chart-col {
  min-width: 30% !important;
}
// ~~~~~~~~~~~~~ Listing Table ~~~~~~~~~~~~~

.monitoring-aps-table {
  max-width: 100%;
  .uhe-pagination {
    padding: 16px 0;
    text-align: center;
    
  }

  .table-input {
    height: 40px;
  }
}

.monitoring-aps-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table {
  border-top: 1px solid #7d7d7d;
  border-radius: 0;
}

.grid-view-table {
  .ant-table-tbody {
    display: none;
  }
}

.grid-view {
  margin-top: 4px;
}

.table-view-btn {
  display: flex;
  align-items: center;
  padding: 0;
  background: none;
  border: none;
  margin-bottom: 3px;
  box-shadow: none;
  .table-view-icon {
    width: 20px;
    height: 20px;
    margin-right: 3px;
    font-size: 1.5em;
    color: @label-text-color !important;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    .st0,
    .st1,
    line,
    rect {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  &:active,
  &:focus {
    background: none;
    color: #28aaeb;
    .table-view-icon {
      .svgColours(#28aaeb);
      color: #28aaeb !important;
    }
  }
  &:hover {
    background: none;
    color: #28aaeb;
    .table-view-icon {
      .svgColours(#28aaeb);
      color: #28aaeb !important;
    }
  }
}

.grid-view-btn-selected,
.column-view-btn-selected {
  color: #28aaeb !important;
  .table-view-icon {
    .svgColours(#28aaeb);
  }
}

// ~~~~~~~~~~~~~ Grid View Card ~~~~~~~~~~~~~

@media(min-width: 1101px) and (max-width: 1350px) {
  .grid-view-wrapper {
    min-width: 33.3%;
  }
}

@media(max-width: 1100px) {
  .grid-view-wrapper {
    min-width: 50%;
  }
}

.grid-view-card {
  background-color: #09365A;
  overflow: hidden;
  border: none;
  &:hover {
    outline: 2px solid #fff;
  }
  .ant-card-body {
    padding: 10px;
    min-height: 175px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media(max-width: 1400px) {
    .ant-card-body {
      width: auto;
    }
  }
}

.grid-view-card--active {
  outline: 2px solid #e8e8e8;
  .grid-view-card__room > a {
    color: #fff;
  }
}

.grid-view-card__header {
  border-bottom: 1px solid #676a6e;
  margin-bottom: 10px;

  p {
    display: flex;
    justify-content: flex-start;
  }
}

.grid-view-card__room {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-height: 15px;
}
.grid-view-card__room > a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: @label-text-color;
}

.grid-view-card__id {
  color: @label-text-color;
  font-size: 10px;
}

.grid-view-card__type {
  color: @label-text-color;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-view-card--blue {
  color: #327cc5;
}

.grid-view-card__body {
  color: #b8babb;
  font-size: 10px;
  .grid-view-card--blue {
    margin-bottom: 3px;
  }
  .ant-row {
    margin: 0;
  }
  .ant-col {
    display: flex;
    flex-direction: column;
  }
  .grid-view-card__upper-row {
    margin-bottom: 10px;
  }
  .grid-view-card__status {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .grid-view-card__status-icon {
    width: 12px;
    height: 12px;
  }
  div {
    align-items: flex-start;
  }
}

// ~~~~~~~~~~~~~ Side Panel ~~~~~~~~~~~~~
.smaller-table {
  width: 100%;
}

.open-sidepanel {
  width: calc(100% - 315px);
}

.monitoring-aps-with-sidepanel {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: relative;
}

.side-panel {
  display: none;
  background: #083659;
  color: @label-text-color;
  border: none;
  box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.7);
  .ant-card-body {
    border-radius: 6px;
  }
}

.side-panel.open {
  display: block;
  min-width: 280px;
  margin-left: 40px;
  margin-right: 30px;
}

.side-panel-row {
  margin-left: 10px;
}

.side-panel-section {
  margin-top: 30px;
}

.side-panel-label {
  color: #0092D7;
  margin-right: 10px;
}

.side-panel-title {
  display: flex;
  justify-content: space-between;
  align-items:flex-start;
  margin-left: 10px;
  margin-bottom: 20px;
  text-align: left;
}

.side-panel-title a {
  color: @label-text-color;
  font-size: 24px;
  margin-right: 10px;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.side-panel-btn {
  background: transparent;
  border: none;

  &:hover {
    background: transparent;
    border: none;
  }
}

.side-panel-title-icon {
  display: flex;
  flex-direction: row;
  align-items: center;

}

.side-panel-btn span:first-child {
  color: #0092D7;
}
.selected-row {
  background: #083659;
}

.column-item {
  .ant-checkbox-inner {
    background-color: inherit;
    border-radius: 5px;
    border: 1px solid;
    border-color:#cbcbcb !important;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border: 0;
      background-color: #0092D7;
    }
  }

  .ant-checkbox-checked + span {
    color: #0092D7;
  }
}

.ant-table-cell > div {
  min-height: 18px;
}

.ant-table-cell p {
  margin: 7px 0;
}

.matching-results-label {
  color: #0092D7;
}

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding-top: 0;
}

.not-configured > td {
  color: #7D7D7D;
  a {
    color: #7D7D7D;
  }
}

.monitoring-aps-table .custom-scrollbar {
  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th {
    border-bottom: none;
  }
  .ant-table.ant-table-empty .ant-table-container {
    border-bottom: 1px solid @text-color-dark-grey;
  }
}

.monitoring-aps-table {
  .ant-cascader-menu-item {
    text-align: left;
  }
}

.dashboard {

  .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0;
    padding-left: 10px;
  }

  .ant-collapse-header {
    height: 40px;
    padding-bottom: 0;
    padding-left: 10px;
  }

  .chart-map-section {
    height: 390px;
  }
  
  @media (max-width:1200px) {
    .chart-map-section {
      height: auto;
    }
  }

  @media (min-width: 951px) and (max-width:1100px) {
    #map-filter-holder {
      padding: 0;
    }
  }

  @media (max-width:950px) {
    .chart-map-section {
      display: flex;
      flex-direction: column;

      .pie-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .gx-card.pie-chart-card {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          height: auto;
          margin: 0 0 15px 0;
        }
        .gx-card.pie-chart-card .ant-card-body {
          height: auto;
        }
      }
    }
    
    .chart-col {
      flex: 1;
    }

    .map-col {
      margin-left: 0;
    }
  }

  .show-hide-chart-label .chart-icon {
    vertical-align: text-top;
  }
}

@media (min-width: 700px) and (max-width:1200px) {
  .unSelectedChart, .selectedChart {
    margin-right: 20px;
  }
}


.uhe-units-wrapper {
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 0;
  }
}

@media (min-width: 768px) and (max-width:1200px) {
  .side-panel{
    transform: translateX(400%);
    transition: transform 0.5s ease-out;
  }
  .side-panel.open {
    position: absolute;
    min-width: 280px;
    max-width: 280px;
    margin-right: 0;
    right: 0;
    transform: translateX(0);
    z-index: 1000;
    height: inherit;

  }
  .open-sidepanel {
    width: 100%;
  }

}

@media (max-width: 768px) {
  .side-panel{
    display: none;
  }
  .monitoring-aps-with-sidepanel{
    position: unset;
  }
  .side-panel.open {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99;
    margin-left: 0px;
    width: 100vw;
    height: 100vh;
  }
  .side-panel-title a {
    max-width: 400px;
  }
}

@media (max-width: 500px) {
  .side-panel-title a {
    max-width: 150px;
  }
}
@media (max-width: 650px) {
  .pie-wrap {
    display: flex;
    flex-direction: column;
  }
}


@media (min-width: 768px) {
  .monitoring-aps-table.open-sidepanel {
    width: calc(100% - 320px);
    transition: width 0.2s ease;
  }

  .monitoring-aps-table:not(.open-sidepanel) {
    width: 100%;
    transition: width 0.2s ease;
  }
}

@media screen and (max-width: 1250px) {
  .table-info {
    flex-wrap: wrap;
    .pie-drop-down {
      margin-right: 15px;
    }
    .table-view-info {
      flex-wrap: wrap;
    }
  }
}