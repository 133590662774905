/* ~~~~~~~~~~~~ Titles ~~~~~~~~~~~~ */
.title-txt(@txtTransform, @align: center) {
  text-transform: @txtTransform;
  text-align: @align;
  color: @secondary-title;
  margin: 0;
}


/* ~~~~~~~~~~~~ Buttons ~~~~~~~~~~~~ */

// Reset Button
.reset() {
  color: #000;
  background: #dee0e0;
  text-transform: uppercase;
  border: none;
  border-radius: 3px;
  padding: 10px 30px;
  cursor: pointer;
}

.svgColours(@svgStroke) {
  .st0,
  .st1,
  line,
  rect {
    stroke: @svgStroke;
  }
}

// ~~~~~~~~~~~~ Layout ~~~~~~~~~~~~
.flex-space() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


// ~~~~~~~~~~~~ Status ~~~~~~~~~~~~
.deviceStatus(@colour, @transform, @border-radius) {
  transform: @transform;
  width: 15px;
  height: 15px;
  border-radius: @border-radius;
  background-color: @colour;
}
