.state-ogranization-field {
  width: "117px"
}

.org-address-item {
  & > div:nth-child(2) {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .org-address-item {
    & > div:nth-child(2) {
      margin-left: 0;
    }
  }
}

.manageOrganization__status--auditInfo {
  margin-top: 10px;
  > * {
    padding: 0 !important;
    margin-left: 12px !important;
  }
}

.align-row {
  padding: 0!important;
}

.align-row > div:nth-child(1) > div > div:nth-child(2),
.align-row > div:nth-child(4) > div > div:nth-child(2) {
  margin-left: 0px!important;
}

.align-row > div:nth-child(1),
.align-row > div:nth-child(4) {
  padding: 0px!important;
}

.align-label > div:nth-child(1)  {
  @media(min-width: 1200px){
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
}
