.upload-log-wrapper {
  margin-bottom: 50px;
  .upload-log-title {
    margin-bottom: 5px;
    color: #17c0d9;
    margin-left: 5px;
  }
  .upload-log-table {
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  }
}

.uhe-table.bulk-upload-table {
  margin-bottom: 3rem
}

.upload-log-head {
  display: flex;
  align-items: center;
}

.upload-log-head h2 {
  margin-right: 50px;
}

.ant-card-upload {
  border: @main-border-separator
}
