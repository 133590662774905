@import "../../ui/uhe/uheVariables";
@import "../../ui/uhe/uheMixins.less";

.facility-edit-card {
  display: flex;
  align-items: center;
}

.facility-edit {
  .search-box {
    top: 2% !important;
  }
}

.facility-row {
  display: flex;
  align-items: center;
  margin: 0 !important;
  .ant-form-item-explain {
    position: absolute;
  }
}
.facility-row.verified-checkbox {
  margin: 6px 0 !important;
}

.facility-map-container {
  height: 550px;
}

.facility-map {
  margin-top: 15px;
  margin-right: 5px;
}

.facility-map-input {
  width: 300px;
}

@media (max-width: 1060px) and (min-width: 610px) {
  .facility-edit {
    .search-box {
      left: 212px !important;
    }
  }
}

@media (max-width: 610px) {
  .facility-edit {
    .search-box {
      top: 10% !important;
    }
  }
}
