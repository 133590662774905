
//global style variables
@dark-theme-primary-color: #F5F5F5;
@dark-theme-primary-color: #38424B;
@dark-theme-background-gradient: linear-gradient(140deg, rgba(8,54,89,1) 0%, rgba(0,0,0,1) 100%);
@dark-theme-secondary-color: lighten(@dark-theme-primary-color, 10%);
@dark-theme-text-color: #545454;
@dark-theme-heading-color: lighten(@dark-theme-text-color, 5%);
@dark-theme-sidebar-dark-bg: darken(@dark-theme-primary-color, 2%);
@dark-theme-component-background: #fff;
@dark-theme-sidebar-dark-text-color: #a1a1a1;
@dark-theme-sidebar-dark-bg-darken: #fff;
@dark-theme-sidebar-dark-hover-color: @dark-theme-component-background;
@dark-theme-border-color: #495762;
@lite-theme-main-red-color: #ed65a4;
@lite-theme-red-icon: #f5222d;
@lite-theme-green-icon: #c2db70;
@lite-theme-lite-blue: #17c0d9;
@lite-theme-menu-bg: #183E6C;
@lite-theme-white-color: #fff;
@lite-theme-main-tezt-color: #545454;

/*Base Styles*/
body.lite-theme {
  &.framed-layout,
  &.boxed-layout {
    background-color: darken(@dark-theme-primary-color, 10%);
  }
}

.lite-theme {
  color: @dark-theme-text-color;
  background: @dark-theme-background-gradient;

  & .@{class-prefix}-link {
    color: darken(@dark-theme-text-color, 10%);
  }

  & .@{class-prefix}-link:hover,
  & .@{class-prefix}-link:focus {
    color: lighten(@dark-theme-text-color, 3%);
  }

  & .@{class-prefix}-social-link {
    & li .@{class-prefix}-link {
      color: @dark-theme-text-color;
    }

    & li.active .@{class-prefix}-link,
    & li .@{class-prefix}-link:hover,
    & li .@{class-prefix}-link:focus {
      color: lighten(@dark-theme-text-color, 30%);
    }
  }

  & h1, & h2, & h3, & h4, & h5, & h6,
  & .h1, & .h2, & .h3, & .h4, & .h5, & .h6 {
    color: @dark-theme-heading-color;
  }

  & .ant-modal h1, & .ant-modal h2, & .ant-modal h3, & .ant-modal h4, & .ant-modal h5, & .ant-modal h6,
  & .ant-modal .h1, & .ant-modal .h2, & .ant-modal .h3, & .ant-modal .h4, & .ant-modal .h5, & .ant-modal .h6 {
    color: @dark-theme-primary-color;
  }

  & .@{class-prefix}-drawer-sidebar-dark .ant-drawer-content {
    background-color: @dark-theme-sidebar-dark-bg;
    color: @dark-theme-text-color;
  }

  & .ant-drawer-right .ant-drawer-content-wrapper,
  & .ant-drawer-right .ant-drawer-content {
    background: @dark-theme-primary-color;
  }

  & .ant-drawer-close {
    color: @dark-theme-text-color;

    &:hover,
    &:focus {
      color: @white-color;
    }
  }

  & .ant-layout,
  & .ant-layout.ant-layout-has-sider > .ant-layout {
    background: @dark-theme-background-gradient;
  }

  & .ant-layout-header {
    background: transparent;
    color: @dark-theme-text-color;
  }

  & .@{class-prefix}-news-content {
    color: @dark-theme-text-color;
  }

  & .@{class-prefix}-header-horizontal {
    background: darken(@dark-theme-primary-color, 5%);
  }

  & .@{class-prefix}-header-horizontal-main {
    color: @dark-theme-text-color;
    background: none;
  }

  & .@{class-prefix}-header-horizontal-dark {
    &:before {
      background: lighten(@dark-theme-primary-color, 5%);
    }

    & .@{class-prefix}-header-search .@{class-prefix}-lt-icon-search-bar-lg input[type="search"] {
      border-color: @border-color;
    }
  }

  & .@{class-prefix}-above-header-horizontal .@{class-prefix}-header-horizontal-nav-curve {

    & .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
      color: darken(@dark-theme-primary-color, 5%);
    }

    & .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
      color: @menu-dark-highlight-color;
    }
  }

  & .@{class-prefix}-below-header-horizontal .@{class-prefix}-header-horizontal-nav-curve,
  & .@{class-prefix}-inside-header-horizontal .@{class-prefix}-header-horizontal-nav-curve {

    & .ant-menu-horizontal > .ant-menu-item:hover > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-selected > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-selected > .ant-menu-submenu-title:before {
      color: @dark-theme-primary-color;
    }

    & .ant-menu-horizontal > .ant-menu-submenu:hover > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-active > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-active > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-item-open > .ant-menu-submenu-title:before,
    & .ant-menu-horizontal > .ant-menu-submenu-open > .ant-menu-submenu-title:before {
      color: @menu-dark-highlight-color;
    }
  }

  & .@{class-prefix}-menu-horizontal {
    & .ant-menu-submenu:hover > .ant-menu-submenu-title,
    & .ant-menu-item-active > .ant-menu-submenu-title,
    & .ant-menu-submenu-active > .ant-menu-submenu-title,
    & .ant-menu-item-open > .ant-menu-submenu-title,
    & .ant-menu-submenu-open > .ant-menu-submenu-title {
      color: @menu-dark-highlight-color;
    }
  }

  & .gx-layout-footer-content {
    text-align: center;
  }

  & .ant-layout-footer {
    background: @dark-theme-primary-color;
    color: @dark-theme-text-color;
    border-top-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-nav-header {
    background-color: @dark-theme-primary-color;
    border-bottom-color: @dark-theme-border-color;
  }

  & .ant-menu {
    color: @dark-theme-text-color;
    background: @dark-theme-sidebar-dark-bg;
  }

  & .ant-menu-inline,
  & .ant-menu-vertical,
  & .ant-menu-vertical-left {
    border-right-color: @dark-theme-border-color;
  }

  & .ant-menu-dark {
    color: @dark-theme-text-color;
    //background: @dark-theme-sidebar-dark-bg;
    background-color: @lite-theme-menu-bg;

    & .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
    & .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
      background: @white-color;
    }
  }

  & .ant-menu-submenu-title {
    &:hover {
      color: @menu-dark-highlight-color;
    }
  }

  & .ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
    color: @dark-theme-text-color;

    &:hover {
      color: @menu-dark-highlight-color;
    }
  }

  & .ant-menu-dark .ant-menu-sub {
    color: @dark-theme-text-color;
    background: none;

    & .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
    & .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
      background: @white-color;
    }

    & .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
    & .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
      background: @menu-dark-highlight-color;
    }
  }

  & .ant-menu-item > a {
    color: @dark-theme-text-color;

    &:hover {
      color: @menu-dark-highlight-color;
    }
  }

  & .ant-menu-item-selected > a:hover {
    color: @menu-dark-highlight-color;
  }

  & .ant-menu-dark.ant-menu-submenu-popup,
  & .ant-menu-submenu-popup {
    background: @dark-theme-primary-color;
    .border-radius(@border-radius-lg);
  }

  & .ant-menu-dark.ant-menu-submenu-popup .ant-menu-sub {

    & .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
    & .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
      background: @white-color;
    }
  }

  & .ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background: @menu-dark-highlight-color;
  }

  & .ant-menu-dark.ant-menu-horizontal {
    border-bottom-color: @dark-theme-sidebar-dark-bg;
  }

  & .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  & .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    border-color: @dark-theme-sidebar-dark-bg;
  }

  & .ant-menu-horizontal {
    border-bottom-color: @dark-theme-border-color;
    background: @dark-theme-primary-color;
  }

  & .@{class-prefix}-header-horizontal-nav .ant-menu-horizontal {
    background: none;
  }

  & .ant-menu-horizontal > .ant-menu-item:hover,
  & .ant-menu-horizontal > .ant-menu-submenu:hover,
  & .ant-menu-horizontal > .ant-menu-item-active,
  & .ant-menu-horizontal > .ant-menu-submenu-active,
  & .ant-menu-horizontal > .ant-menu-item-open,
  & .ant-menu-horizontal > .ant-menu-submenu-open,
  & .ant-menu-horizontal > .ant-menu-item-selected,
  & .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom-color: @dark-theme-text-color;
    color: @dark-theme-text-color;
  }

  & .ant-menu-horizontal > .ant-menu-item > a,
  & .ant-menu-horizontal > .ant-menu-submenu > a {
    color: @dark-theme-text-color;
  }

  // & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //   background-color: lighten(@dark-theme-component-background, 5%);
  //   color: @dark-theme-text-color;
  // }

  // & .ant-layout-sider .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //   background-color: fade(@white-color, 12%);
  // }

  // & .ant-layout-sider.@{class-prefix}-mini-custom-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //   background-color: transparent;

  //   & > a:hover {
  //     color: @menu-dark-bg;
  //   }
  // }

  & .ant-menu-submenu > .ant-menu,
  & .ant-menu-item:active,
  & .ant-menu-submenu-title:active {
    background-color: lighten(@dark-theme-component-background, 5%);
    color: @dark-theme-text-color;
  }

  & .ant-layout-sider .ant-menu-submenu > .ant-menu,
  & .ant-layout-sider .ant-menu-item:active,
  & .ant-layout-sider .ant-menu-submenu-title:active {
    background-color: transparent;
  }

  & .ant-menu-item-group-title,
  & .ant-menu-item-selected,
  & .ant-menu-submenu-selected .ant-menu-submenu-title {
    color: @dark-theme-text-color;
  }

  & .ant-menu-item:hover,
  & .ant-menu-item-active,
  & .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  & .ant-menu-submenu-active {
    color: @dark-theme-text-color;
  }

  & .ant-menu-item-disabled,
  & .ant-menu-submenu-disabled {
    color: darken(@dark-theme-text-color, 25%) !important;
  }

  & .ant-menu-dark .ant-menu-item-group-title {
    color: @dark-theme-text-color;
  }

  & .ant-menu-dark .ant-menu-item {
    color: @dark-theme-text-color;
  }

  & .ant-menu-dark .ant-menu-item > a {
    color: @white-color;

    &:hover {
      color: @menu-dark-highlight-color;
    }
  }

  & .ant-menu-dark .ant-menu-item-selected > a {
    color: @menu-dark-highlight-color;
  }

  & .ant-menu-submenu-horizontal {
    & > .ant-menu-submenu-title {
      color: @dark-theme-text-color;
    }
  }

  & .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  & .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  & .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  & .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  & .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background: @white-color;
  }

  & .ant-menu-vertical .ant-menu-item::after,
  & .ant-menu-vertical-left .ant-menu-item::after,
  & .ant-menu-vertical-right .ant-menu-item::after,
  & .ant-menu-inline .ant-menu-item::after {
    border-right-color: darken(@dark-theme-border-color, 30%);
  }

  & .ant-layout-sider,
  & .@{class-prefix}-layout-sider-dark {
    background-color: @dark-theme-sidebar-dark-bg;
    color: @dark-theme-sidebar-dark-text-color;
    .box-shadow(0px 0 4px rgba(0, 0, 0, 0.35));
  }

  & .@{class-prefix}-task-list-item {

    &:not(:last-child) {
      border-bottom-color: @dark-theme-border-color;
    }

    &:hover .gx-text-hover {
      color: darken(@dark-theme-text-color, 10%);
    }
  }

  & .@{class-prefix}-card-ticketlist .@{class-prefix}-task-list-item {

    &:hover {
      background-color: transparent;

      & .@{class-prefix}-task-item-title {
        color: lighten(@dark-theme-text-color, 3%);
      }
    }
  }

  & .@{class-prefix}-card-testimonial-content {
    background-color: @dark-theme-primary-color;

    &:before {
      border-right-color: @dark-theme-primary-color;
    }
  }

  & .@{class-prefix}-progress-task-list {
    & + .@{class-prefix}-progress-task-list {
      border-top-color: @dark-theme-border-color;
    }
  }

  & .@{class-prefix}-card-ticketlist {
    & .@{class-prefix}-link {
      color: darken(@dark-theme-text-color, 10%);
    }
  }

  & .@{class-prefix}-overview-description {
    border-left-color: @dark-theme-border-color;

    @media screen and (max-width: @screen-sm-max) {
      border-top-color: @dark-theme-border-color;
      border-bottom-color: @dark-theme-border-color;
    }
  }

  & .@{class-prefix}-revenu {

    &-total {
      border-bottom-color: @dark-theme-border-color;
    }

    &-col:not(:last-child) {
      border-right-color: @dark-theme-border-color;
    }
  }

  & .@{class-prefix}-product-item {
    background: @dark-theme-component-background;
  }

  & .@{class-prefix}-login-content {
    background-color: @dark-theme-component-background;

    & .ant-input {
      background-color: @grey-6;

      &:focus {
        border-color: @dark-theme-primary-color;
      }
    }
  }

  & .@{class-prefix}-app-login-main-content {
    background-color: @dark-theme-component-background;
  }

  & .@{class-prefix}-app-login-content .ant-input {
    background-color: @grey-6;

    &:focus {
      border-color: @dark-theme-primary-color;
    }
  }

  & .@{class-prefix}-app-logo-content {
    color: @dark-theme-text-color;

    &:before {
      background-color: fade(@dark-theme-primary-color, 70%);
    }

    & h1 {
      color: @dark-theme-text-color;
    }
  }

  & .@{class-prefix}-app-social-block {

    & .@{class-prefix}-social-link span {
      border-color: @dark-theme-primary-color;
      color: @dark-theme-primary-color;

      &:hover,
      &:focus {
        color: @dark-theme-text-color;
        background-color: @dark-theme-primary-color;
      }
    }
  }

  & .@{class-prefix}-avatar-img,
  & .@{class-prefix}-avatar,
  & .@{class-prefix}-badge-outline {
    border-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-btn,
  & .btn {
    color: @btn-default-color;
    border-color: @btn-default-border;
  }

  & .@{class-prefix}-sub-popover .@{class-prefix}-btn,
  & .@{class-prefix}-sub-popover .btn {
    &:focus,
    &:hover {
      background-color: @dark-theme-text-color;
    }
  }

  & .@{class-prefix}-btn-link {
    color: darken(@dark-theme-text-color, 10%);
    background-color: transparent;

    &:hover {
      color: lighten(@dark-theme-text-color, 3%);
    }
  }

  & .@{class-prefix}-ant-card-actions > li {
    &:not(:last-child) {
      border-right-color: @dark-theme-border-color;
    }
  }

  & .ant-card {
    color: @dark-theme-text-color;
    background: @dark-theme-component-background;

    &-bordered {
      border-color: @dark-theme-border-color;
    }

    &-head {
      border-color: @dark-theme-border-color;

      &-title {
        color: @dark-theme-text-color;
      }
    }

    &-type-inner .ant-card-head {
      background: @dark-theme-component-background;
    }

    &-meta-title,
    &-meta-description {
      color: @dark-theme-text-color;
    }

    &-actions {
      border-top-color: @dark-theme-border-color;
      background: @dark-theme-component-background;

      > li {
        color: @dark-theme-text-color;

        &:not(:last-child) {
          border-right-color: @dark-theme-border-color;
        }

        & > span:hover {
          color: lighten(@dark-theme-text-color, 3%);
        }
      }
    }

    &-grid {
      box-shadow: 1px 0 0 0 @dark-theme-border-color, 0 1px 0 0 @dark-theme-border-color, 1px 1px 0 0 @dark-theme-border-color, 1px 0 0 0 @dark-theme-border-color inset, 0 1px 0 0 @dark-theme-border-color inset;

      &:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      }
    }
  }

  & .@{class-prefix}-card {
    background-color: @dark-theme-component-background;
  }

  & .@{class-prefix}-card-overview {
    background-color: @dark-theme-component-background;
  }

  & .@{class-prefix}-card-metrics,
  & .@{class-prefix}-card-widget {
    .box-shadow(0 1px 4px 0 rgba(0, 0, 0, .15));
  }

  & .@{class-prefix}-icon-views:hover {
    background-color: @dark-theme-primary-color;
    color: @dark-theme-text-color;
  }

  & .@{class-prefix}-search-bar {

    & input[type="search"] {
      background: fade(@grey-2, 10%);

      &:focus {
        background-color: fade(@grey-2, 30%);
      }
    }

    & .@{class-prefix}-search-icon {
      color: lighten(@grey-6, 10%);
    }
  }

  & .@{class-prefix}-chat-search-bar {

    & input[type="search"]:focus {
      background-color: @dark-theme-component-background;
    }
  }

  & .@{class-prefix}-user-list {

    &.@{class-prefix}-card-list {
      background: @dark-theme-component-background;
    }

    & .@{class-prefix}-link[class*="gx-meta-"] {
      color: @dark-theme-text-color;
    }
  }

  & .@{class-prefix}-card-strip:not(:last-child) {
    border-bottom-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-loader-container {
    background-color: @grey-6;
  }

  & .@{class-prefix}-table {

    & th,
    & td {
      border-top-color: @dark-theme-border-color;
    }

    & thead th {
      border-bottom-color: @dark-theme-border-color;
    }

    & tbody + tbody {
      border-top-color: @dark-theme-border-color;
    }
  }

  & .@{class-prefix}-table-bordered {
    border-color: @dark-theme-border-color;

    & th,
    & td {
      border-color: @dark-theme-border-color;
    }
  }

  & .@{class-prefix}-timeline-item:before {
    border-left-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-timeline-badge {
    color: @dark-theme-component-background;

    &:after {
      border-color: @dark-theme-border-color;
    }
  }

  & .@{class-prefix}-timeline-panel {
    background-color: @dark-theme-component-background;
    border-color: @dark-theme-border-color;

    &:before {
      border-color: transparent @dark-theme-border-color;
    }

    &:after {
      border-color: transparent @dark-theme-component-background;
    }
  }

  & .@{class-prefix}-timeline-center .@{class-prefix}-timeline-inverted:before {
    border-right-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-timeline-info .ant-timeline-item-content {
    color: @dark-theme-text-color;

    & .@{class-prefix}-link {
      color: darken(@dark-theme-text-color, 5%);

      &:hover,
      &:focus {
        color: @white-color;
      }
    }
  }

  & .@{class-prefix}-img-more {
    background-color: darken(@dark-theme-component-background, 6%);
  }

  & .ant-tabs {
    color: @dark-theme-text-color;

    &-nav .ant-tabs-tab-active,
    &-nav .ant-tabs-tab:hover {
      color: darken(@dark-theme-text-color, 10%);
    }

    &-ink-bar {
      background-color: darken(@dark-theme-text-color, 10%);
    }

    &-bar {
      border-bottom-color: @dark-theme-border-color;
    }

    &-tab-prev,
    &-tab-next {
      color: @dark-theme-text-color;

      &:hover {
        color: @white-color;
      }
    }

    &-tab-btn-disabled,
    &-tab-btn-disabled:hover {
      color: darken(@dark-theme-text-color, 40%);
    }

    &.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
      border-color: lighten(@dark-theme-component-background, 8%);
      border-bottom-color: @dark-theme-component-background;
      background: lighten(@dark-theme-component-background, 2%);
    }

    &.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
      background: @dark-theme-component-background;
      border-color: lighten(@dark-theme-component-background, 8%);
      border-bottom-color: @dark-theme-component-background;
      color: @white-color;
    }

    &.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab .anticon-close {
      color: @dark-theme-text-color;

      &:hover {
        color: @white-color;
      }
    }
  }

  & .ant-checkbox {
    &-wrapper {
      color: @dark-theme-text-color;
    }

    &-disabled + span {
      color: darken(@dark-theme-text-color, 30%);
    }

    &-checked::after,
    &-wrapper:hover .ant-checkbox-inner,
    &:hover .ant-checkbox-inner,
    &-input:focus + .ant-checkbox-inner {
      border-color: lighten(@dark-theme-component-background, 20%);
    }

    &-checked .ant-checkbox-inner,
    &-indeterminate .ant-checkbox-inner {
      background-color: lighten(@dark-theme-component-background, 20%);
      border-color: lighten(@dark-theme-component-background, 20%);
    }
  }

  & .ant-timeline {
    color: @dark-theme-text-color;

    &-item-head {
      background-color: transparent;
    }

    &-item-tail {
      border-left-color: lighten(@dark-theme-border-color, 8%);
    }

    &-item-head-blue {
      border-color: lighten(@dark-theme-border-color, 8%);
      color: lighten(@dark-theme-text-color, 3%);
    }
  }

  & .ant-popover {
    &-inner,
    &-arrow {
      background-color: @dark-theme-component-background;
    }

    &-inner {
      .box-shadow(0 0 4px rgba(0, 0, 0, 0.28));
    }

    &-inner-content,
    &-message {
      color: @dark-theme-text-color;
    }

    &-title {
      border-bottom-color: @dark-theme-border-color;
      color: @dark-theme-text-color;
    }
  }

  & .@{class-prefix}-sub-popover li:not(:last-child),
  & .@{class-prefix}-popover-header {
    border-bottom-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-user-popover li {
    &:hover,
    &:focus {
      background-color: lighten(@dark-theme-component-background, 5%);
    }
  }

  & .ant-divider {
    color: @dark-theme-text-color;
    background: @dark-theme-border-color;

    &.ant-divider-with-text,
    &.ant-divider-with-text-left,
    &.ant-divider-with-text-right {
      background: none;
    }
  }

  & .@{class-prefix}-card-body-border-top .ant-card-body {
    border-top-color: @dark-theme-border-color;
  }

  & .ant-list {
    color: @dark-theme-text-color;

    &-split .ant-list-item,
    &-split .ant-list-header,
    &-something-after-last-item .ant-spin-container > .ant-list-item:last-child {
      border-bottom-color: @dark-theme-border-color;
    }

    &-item-meta-description,
    &-vertical .ant-list-item-content,
    &-item-action > li {
      color: @dark-theme-text-color;
    }

    &-bordered {
      border-color: @dark-theme-border-color;
    }

    &-item-action-split {
      background-color: @dark-theme-border-color;
    }
  }

  & .@{class-prefix}-btn-light,
  & .@{class-prefix}-link.@{class-prefix}-btn-light {
    color: @dark-theme-text-color !important;
    background-color: @dark-theme-primary-color !important;
    border-color: @dark-theme-primary-color !important;

    &:hover,
    &:focus {
      background-color: darken(@dark-theme-primary-color, 10%) !important;
      border-color: darken(@dark-theme-primary-color, 10%) !important;
    }
  }

  & .ant-table {
    color: @dark-theme-text-color;

    &-thead > tr > th {
      background: lighten(@dark-theme-component-background, 5%);
      color: @dark-theme-text-color;
      border-bottom-color: @dark-theme-border-color;
    }

    &-tbody > tr > td {
      border-bottom-color: @dark-theme-border-color;
    }

    &-thead > tr,
    &-tbody > tr {
      &.@{table-prefix-cls}-row-hover > td,
      &:hover > td {
        background: lighten(@dark-theme-component-background, 5%);
      }
    }

    &-tbody > tr.ant-table-row-selected td {
      background: lighten(@dark-theme-component-background, 5%);
    }

    &-small {
      border-color: @dark-theme-border-color;
    }

    &-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
    &-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
    &-small > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
    &-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
    &-small > .ant-table-content > .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead > tr > th,
    &-small > .ant-table-content > .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead > tr > th,
    &-small > .ant-table-content > .ant-table-fixed-left > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th,
    &-small > .ant-table-content > .ant-table-fixed-right > .ant-table-body-outer > .ant-table-body-inner > table > .ant-table-thead > tr > th {
      background: lighten(@dark-theme-component-background, 5%);
      border-bottom-color: @dark-theme-border-color;
    }

    &.ant-table-bordered .ant-table-title,
    &-bordered .ant-table-header > table,
    &-bordered .ant-table-body > table,
    &-bordered .ant-table-fixed-left table,
    &-bordered .ant-table-fixed-right table,
    &.ant-table-bordered .ant-table-footer {
      border-color: @dark-theme-border-color;
    }

    &-header {
      background: lighten(@dark-theme-component-background, 5%);
    }

    &-footer {
      background: lighten(@dark-theme-component-background, 5%);

      &:before {
        background: transparent;
      }
    }

    &-bordered .ant-table-thead > tr > th,
    &-bordered .ant-table-tbody > tr > td {
      border-right-color: @dark-theme-border-color;
    }

    &-bordered .ant-table-thead > tr:not(:last-child) > th {
      border-bottom-color: @dark-theme-border-color;
    }

    &-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
      background: @dark-theme-component-background;
    }

    &-fixed-left table,
    &-fixed-right table {
      background: lighten(@dark-theme-component-background, 5%);
    }

    &-thead > tr > th .anticon-filter,
    &-thead > tr > th .ant-table-filter-icon {
      color: @dark-theme-text-color;

      &:hover {
        color: @white-color;
      }
    }

    &-column-sorter {
      color: @dark-theme-text-color;

      &-up:hover .anticon,
      &-down:hover .anticon,
      &-up.on .anticon-caret-up,
      &-down.on .anticon-caret-up,
      &-up.on .anticon-caret-down,
      &-down.on .anticon-caret-down {
        color: @white-color;
      }
    }

    &-row-expand-icon {
      background: none;
    }
  }

  tr.ant-table-expanded-row,
  tr.ant-table-expanded-row:hover {
    background: lighten(@dark-theme-component-background, 5%);
  }

  & .ant-pagination {
    color: @dark-theme-text-color;

    &-item {
      background-color: darken(@dark-theme-component-background, 5%);
      border-color: @dark-theme-border-color;

      &-active,
      &:hover,
      &:focus {
        border-color: @dark-theme-border-color;

        & .@{class-prefix}-link {
          color: @dark-theme-text-color;
        }
      }
    }

    &-disabled a,
    &-disabled:hover a,
    &-disabled:focus a,
    &-disabled .ant-pagination-item-link,
    &-disabled:hover .ant-pagination-item-link,
    &-disabled:focus .ant-pagination-item-link {
      color: darken(@dark-theme-text-color, 45%);
    }

    &-prev,
    &-next,
    &-jump-prev,
    &-jump-next {
      color: @dark-theme-text-color;
    }

    &-prev .ant-pagination-item-link,
    &-next .ant-pagination-item-link {
      background-color: darken(@dark-theme-component-background, 5%);
      border-color: @dark-theme-border-color;
    }

    &-prev:focus .ant-pagination-item-link,
    &-next:focus .ant-pagination-item-link,
    &-prev:hover .ant-pagination-item-link,
    &-next:hover .ant-pagination-item-link {
      border-color: @dark-theme-border-color;
      color: @dark-theme-text-color;
    }

    &-jump-prev:focus:after,
    &-jump-next:focus:after,
    &-jump-prev:hover:after,
    &-jump-next:hover:after {
      color: @dark-theme-text-color;
    }

    &-simple .ant-pagination-simple-pager input {
      background-color: fade(@grey-2, 10%);
      border-color: fade(@grey-2, 10%);
      color: @dark-theme-text-color;

      &:hover {
        border-color: fade(@grey-2, 30%);
      }
    }

    &-options-quick-jumper input {
      background-color: fade(@grey-2, 10%);
      border-color: fade(@grey-2, 10%);
      color: @dark-theme-text-color;

      &:hover {
        border-color: fade(@grey-2, 10%);
      }

      &:focus {
        border-color: @dark-theme-border-color;
        .box-shadow(0 0 0 1px rgba(0, 0, 0, 0.1));
      }
    }
  }

  & .ant-badge {
    color: @dark-theme-text-color;

    &-status-text {
      color: @dark-theme-text-color;
    }

    &-count {
      .box-shadow(0 0 0 1px rgba(0, 0, 0, .15));
    }
  }

  & .ant-cascader {
    &-picker {
      &:focus .ant-cascader-input {
        border-color: @dark-theme-border-color;
        .box-shadow(0 0 0 1px rgba(0, 0, 0, 0.1));
      }
    }

    &-menus {
      background: darken(@dark-theme-component-background, 5%);
    }

    &-menu {
      border-right-color: @dark-theme-border-color;
    }

    &-menu-item:hover {
      background: darken(@dark-theme-component-background, 8%);
    }

    &-picker,
    &-picker-clear {
      background-color: transparent;
      color: @dark-theme-text-color;
    }

    &-picker-arrow,
    &-menu-item-expand:after {
      color: @dark-theme-text-color;
    }

    &-menu-item-active:not(.ant-cascader-menu-item-disabled),
    &-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
      background: lighten(@dark-theme-component-background, 5%);
    }
  }

  & .ant-form {
    &-item,
    &-item-label label,
    &-explain,
    &-extra {
      color: @dark-theme-text-color;
    }
  }

  & .ant-input {
    background-color: fade(@grey-2, 10%) !important;
    border-color: fade(@grey-2, 10%);
    color: @dark-theme-text-color;

    &:focus,
    &:hover {
      border-color: fade(@grey-3, 10%);
      .box-shadow(0 0 0 1px rgba(0, 0, 0, 0.1));
    }

    &-number {
      background-color: fade(@grey-2, 10%);
      border-color: fade(@grey-2, 10%);
      color: @dark-theme-text-color;

      &:focus,
      &:hover {
        border-color: fade(@grey-3, 10%);
        .box-shadow(0 0 0 1px rgba(0, 0, 0, 0.1));
      }
    }

    &-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
      border-color: @dark-theme-border-color;
    }

    &-group-addon {
      background-color: fade(@grey-2, 10%);
      border-color: fade(@grey-2, 10%);
      color: @dark-theme-text-color;

      & .ant-select-open .ant-select-selection,
      & .ant-select-focused .ant-select-selection {
        color: @dark-theme-text-color;
      }
    }

    &-search-icon {
      color: @dark-theme-text-color;
    }

    &-affix-wrapper .ant-input-prefix,
    &-affix-wrapper .ant-input-suffix {
      color: @dark-theme-text-color;

      & i {
        color: @dark-theme-text-color !important;
      }
    }
  }

  & .ant-input.@{class-prefix}-chat-textarea {
    background-color: @dark-theme-component-background !important;
    border-color: @dark-theme-component-background;
    color: @dark-theme-text-color;

    &:focus {
      border-color: @dark-theme-border-color;
    }
  }

  & .ant-modal .ant-input {
    background-color: @white-color !important;
    border-color: lighten(@dark-theme-border-color, 55%);
    color: @grey-8;

    &:focus,
    &:hover {
      border-color: lighten(@dark-theme-border-color, 50%);
    }
  }

  & .ant-modal .ant-upload-list-item-info .anticon-paper-clip {
    color: @grey-8;
  }

  & .ant-mention {
    &-wrapper {
      color: @dark-theme-text-color;

      &.disabled .ant-mention-editor {
        background-color: fade(@grey-4, 10%);
        color: @dark-theme-text-color;
      }

      &.ant-mention-active:not(.disabled) .ant-mention-editor {
        border-color: fade(@grey-3, 10%);
        .box-shadow(0 0 0 1px rgba(0, 0, 0, 0.1));
      }
    }

    &-wrapper .ant-mention-editor {
      background-color: fade(@grey-2, 10%);
      border-color: fade(@grey-2, 10%);
      color: @dark-theme-text-color;

      &:focus {
        border-color: fade(@grey-3, 10%);
        .box-shadow(0 0 0 1px rgba(0, 0, 0, 0.1));
      }
    }
  }

  & .ant-select {
    color: @dark-theme-text-color;

    &-selection {
      background-color: fade(@grey-2, 10%);
      border-color: fade(@grey-2, 10%);

      &:hover {
        border-color: fade(@grey-2, 10%);
      }
    }

    &-arrow {
      color: @dark-theme-text-color;
    }

    &-focused .ant-select-selection,
    &-selection:focus,
    &-selection:active {
      border-color: fade(@grey-3, 10%);
      .box-shadow(0 0 0 1px rgba(0, 0, 0, 0.1));
    }

    &-dropdown {
      color: @dark-theme-text-color;
      background-color: darken(@dark-theme-component-background, 5%);
    }

    &-dropdown-menu-item {
      color: @dark-theme-text-color;

      &:hover {
        background-color: darken(@dark-theme-component-background, 7%);
      }

      &-selected,
      &-selected:hover,
      &-active {
        background-color: darken(@dark-theme-component-background, 8%);
        color: @dark-theme-text-color;
      }
    }

    &-auto-complete.ant-select .ant-input:focus,
    &-auto-complete.ant-select .ant-input:hover {
      border-color: fade(@grey-2, 10%);
    }

    &-dropdown-menu-item-group-title {
      color: @dark-theme-text-color;
    }

    &-auto-complete.ant-select textarea.ant-input {
      background: fade(@grey-2, 10%) !important;
    }

    &-tree,
    &-tree li .ant-select-tree-node-content-wrapper {
      color: @dark-theme-text-color;
    }

    &-tree li .ant-select-tree-node-content-wrapper:hover,
    &-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      background-color: lighten(@dark-theme-component-background, 5%);
    }

    &-tree-dropdown .ant-select-dropdown-search .ant-select-search__field,
    &-tree-checkbox-checked .ant-select-tree-checkbox-inner,
    &-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
      border-color: lighten(@dark-theme-component-background, 5%);
      background-color: lighten(@dark-theme-component-background, 5%);
    }
  }

  & .ant-select-show-search .ant-select-selection {
    background-color: transparent;
    .box-shadow(none);
  }

  & .ant-transfer {
    &-list-search-action,
    &-list-search-action i {
      color: @dark-theme-text-color;
    }

    &-list {
      border-color: lighten(@dark-theme-component-background, 8%);
      color: @dark-theme-text-color;
    }

    &-list-body-with-search {
      background-color: lighten(@dark-theme-component-background, 5%);
    }

    &-list-header {
      background-color: lighten(@dark-theme-component-background, 5%);
      border-color: lighten(@dark-theme-component-background, 8%);
      color: @dark-theme-text-color;
    }

    &-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
      background-color: lighten(@dark-theme-component-background, 8%);
    }

    &-list-footer {
      border-top-color: lighten(@dark-theme-component-background, 8%);
      background-color: lighten(@dark-theme-component-background, 5%);
    }

    &-list-content-item-disabled {
      color: darken(@dark-theme-text-color, 40%);
    }
  }

  & .ant-calendar {
    background-color: lighten(@dark-theme-component-background, 5%);
    border-color: lighten(@dark-theme-component-background, 5%);
    color: @dark-theme-text-color;

    &-today .ant-calendar-date {
      border-color: lighten(@dark-theme-component-background, 25%);
      color: lighten(@dark-theme-component-background, 25%);
      background-color: lighten(@dark-theme-component-background, 15%);
    }

    &-selected-date .ant-calendar-date,
    &-selected-start-date .ant-calendar-date,
    &-selected-end-date .ant-calendar-date,
    &-selected-day .ant-calendar-date {
      background: lighten(@dark-theme-component-background, 25%);
      color: @dark-theme-text-color;
    }

    &-month-panel-selected-cell .ant-calendar-month-panel-month:hover,
    &-month-panel-selected-cell .ant-calendar-month-panel-month,
    &-year-panel-selected-cell .ant-calendar-year-panel-year,
    &-year-panel-selected-cell .ant-calendar-year-panel-year:hover,
    &-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
    &-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover {
      background: lighten(@dark-theme-component-background, 25%);
      color: @dark-theme-text-color;
    }

    &-month-panel-month:hover,
    &-year-panel-year:hover,
    &-decade-panel-decade:hover {
      background: lighten(@dark-theme-component-background, 15%);
    }

    &-input {
      color: @dark-theme-text-color;
      background-color: lighten(@dark-theme-component-background, 5%);
    }

    &-month-panel,
    &-year-panel,
    &-decade-panel {
      background-color: lighten(@dark-theme-component-background, 5%);
    }

    &-header,
    &-input-wrap,
    &-month-panel-header,
    &-year-panel-header,
    &-decade-panel-header {
      border-bottom-color: lighten(@dark-theme-component-background, 8%);
    }

    &-footer,
    &-range .ant-calendar-body,
    &-range .ant-calendar-month-panel-body,
    &-range .ant-calendar-year-panel-body {
      border-top-color: lighten(@dark-theme-component-background, 8%);
    }

    &-picker-icon,
    &-picker-icon:after {
      color: @dark-theme-text-color;
    }

    &-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: @dark-theme-border-color;
    }

    &-header .ant-calendar-prev-century-btn,
    &-header .ant-calendar-next-century-btn,
    &-header .ant-calendar-prev-decade-btn,
    &-header .ant-calendar-next-decade-btn,
    &-header .ant-calendar-prev-month-btn,
    &-header .ant-calendar-next-month-btn,
    &-header .ant-calendar-prev-year-btn,
    &-header .ant-calendar-next-year-btn,
    &-header .ant-calendar-century-select,
    &-header .ant-calendar-decade-select,
    &-header .ant-calendar-year-select,
    &-header .ant-calendar-month-select {
      color: @dark-theme-text-color;
    }

    &-month-panel-header .ant-calendar-month-panel-prev-century-btn,
    &-month-panel-header .ant-calendar-month-panel-next-century-btn,
    &-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
    &-month-panel-header .ant-calendar-month-panel-next-decade-btn,
    &-month-panel-header .ant-calendar-month-panel-prev-month-btn,
    &-month-panel-header .ant-calendar-month-panel-next-month-btn,
    &-month-panel-header .ant-calendar-month-panel-prev-year-btn,
    &-month-panel-header .ant-calendar-month-panel-next-year-btn,
    &-month-panel-header .ant-calendar-month-panel-century-select,
    &-month-panel-header .ant-calendar-month-panel-decade-select,
    &-month-panel-header .ant-calendar-month-panel-year-select,
    &-month-panel-header .ant-calendar-month-panel-month-select {
      color: @dark-theme-text-color;
    }

    &-year-panel-header .ant-calendar-year-panel-prev-century-btn,
    &-year-panel-header .ant-calendar-year-panel-next-century-btn,
    &-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
    &-year-panel-header .ant-calendar-year-panel-next-decade-btn,
    &-year-panel-header .ant-calendar-year-panel-prev-month-btn,
    &-year-panel-header .ant-calendar-year-panel-next-month-btn,
    &-year-panel-header .ant-calendar-year-panel-prev-year-btn,
    &-year-panel-header .ant-calendar-year-panel-next-year-btn,
    &-year-panel-header .ant-calendar-year-panel-century-select,
    &-year-panel-header .ant-calendar-year-panel-decade-select,
    &-year-panel-header .ant-calendar-year-panel-year-select,
    &-year-panel-header .ant-calendar-year-panel-month-select {
      color: @dark-theme-text-color;
    }

    &-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
    &-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
    &-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
    &-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
    &-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
    &-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
    &-decade-panel-header .ant-calendar-decade-panel-prev-year-btn,
    &-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
      color: @dark-theme-text-color;
    }

    &-date {
      color: @dark-theme-text-color;

      &:hover {
        background: lighten(@dark-theme-component-background, 15%);
      }
    }

    &-last-month-cell .ant-calendar-date,
    &-next-month-btn-day .ant-calendar-date,
    &-year-panel-last-decade-cell .ant-calendar-year-panel-year,
    &-year-panel-next-decade-cell .ant-calendar-year-panel-year,
    &-decade-panel-last-century-cell .ant-calendar-decade-panel-decade,
    &-decade-panel-next-century-cell .ant-calendar-decade-panel-decade {
      color: darken(@dark-theme-text-color, 20%);
    }

    &-picker-clear {
      color: @dark-theme-text-color;
      background: none;
    }

    & .ant-calendar-ok-btn {
      color: @dark-theme-border-color !important;
      background-color: lighten(@dark-theme-component-background, 25%) !important;
      border-color: lighten(@dark-theme-component-background, 25%) !important;

      &:hover,
      &:focus {
        color: @dark-theme-primary-color !important;
        background-color: lighten(@dark-theme-component-background, 35%) !important;
        border-color: @dark-theme-primary-color !important;
      }
    }

    &-range .ant-calendar-in-range-cell:before {
      background-color: lighten(@dark-theme-component-background, 7%);
    }

    &-week-number .ant-calendar-body tr.ant-calendar-active-week {
      background: lighten(@dark-theme-component-background, 7%);
    }

    &-week-number .ant-calendar-body tr .ant-calendar-selected-day .ant-calendar-date,
    &-week-number .ant-calendar-body tr .ant-calendar-selected-day:hover .ant-calendar-date,
    &-time .ant-calendar-footer .ant-calendar-time-picker-btn-disabled {
      color: @dark-theme-text-color;
    }
  }

  & .ant-fullcalendar {
    color: @dark-theme-text-color;
    border-top-color: @dark-theme-border-color;

    &-value {
      color: @dark-theme-text-color;

      &:hover {
        background: lighten(@dark-theme-component-background, 5%);
      }
    }

    &-fullscreen .ant-fullcalendar-month,
    &-fullscreen .ant-fullcalendar-date {
      color: @dark-theme-text-color;
      border-top-color: lighten(@dark-theme-component-background, 8%);
    }

    &-fullscreen .ant-fullcalendar-month:hover,
    &-fullscreen .ant-fullcalendar-date:hover,
    &-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-month,
    &-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-date {
      background: lighten(@dark-theme-component-background, 5%);
      color: @dark-theme-text-color;
    }

    &-fullscreen .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-month,
    &-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
      border-top-color: lighten(@dark-theme-component-background, 8%);
    }

    &-fullscreen .ant-fullcalendar-last-month-cell .ant-fullcalendar-date,
    &-fullscreen .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-date,
    &-last-month-cell .ant-fullcalendar-value,
    &-next-month-btn-day .ant-fullcalendar-value {
      color: darken(@dark-theme-text-color, 20%);
    }

    &-selected-day .ant-fullcalendar-value,
    &-month-panel-selected-cell .ant-fullcalendar-value {
      background: lighten(@dark-theme-component-background, 5%);
      color: @dark-theme-text-color;
    }

    &-today .ant-fullcalendar-value,
    &-month-panel-current-cell .ant-fullcalendar-value {
      box-shadow: 0 0 0 1px lighten(@dark-theme-component-background, 25%) inset;
      background: lighten(@dark-theme-component-background, 25%);
    }

    &-fullscreen .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
    &-fullscreen .ant-fullcalendar-selected-day .ant-fullcalendar-value {
      color: @dark-theme-text-color;
      background-color: transparent;
      box-shadow: none;
    }
  }

  & .ant-time {
    &-picker-panel,
    &-picker-icon,
    &-picker-icon:after {
      color: @dark-theme-text-color;
    }

    &-picker-panel-inner {
      background-color: lighten(@dark-theme-component-background, 5%);
      .box-shadow(0 2px 8px rgba(0, 0, 0, 0.15));
    }

    &-picker-panel-input-wrap {
      border-bottom-color: lighten(@dark-theme-component-background, 8%);

      & .ant-time-picker-panel-input {
        background-color: transparent;
      }

      & .@{class-prefix}-link,
      & .@{class-prefix}-link:hover,
      & .@{class-prefix}-link:focus,
      & .@{class-prefix}-link.ant-time-picker-panel-clear-btn:after {
        color: @dark-theme-text-color;
      }
    }

    &-picker-input {
      background-color: fade(@grey-2, 10%);
      border-color: fade(@grey-2, 10%);
      color: @dark-theme-text-color;

      &:focus,
      &:hover {
        border-color: fade(@grey-3, 10%);
        .box-shadow(0 0 0 1px rgba(0, 0, 0, 0.1));
      }
    }

    &-picker-panel-select {
      border-left-color: lighten(@dark-theme-component-background, 8%);

      & li:hover {
        background: lighten(@dark-theme-component-background, 7%);
      }
    }
  }

  li.ant-time-picker-panel-select-option-selected {
    background: lighten(@dark-theme-component-background, 8%);
  }

  & .ant-slider {
    &-mark-text {
      color: darken(@dark-theme-text-color, 40%);
    }

    &-mark-text-active {
      color: @white-color;
    }

    &-track {
      background-color: lighten(@dark-theme-component-background, 5%);
    }

    &-active {
      border-color: lighten(@dark-theme-component-background, 5%);
    }
  }

  & .ant-radio {
    color: @dark-theme-text-color;

    &-wrapper {
      color: @dark-theme-text-color;
    }

    &-wrapper:hover .ant-radio .ant-radio-inner,
    &:hover .ant-radio-inner,
    &-focused .ant-radio-inner {
      border-color: lighten(@dark-theme-component-background, 20%);
    }

    &-checked:after {
      border-color: lighten(@dark-theme-component-background, 20%);
    }

    &-checked .ant-radio-inner {
      border-color: lighten(@dark-theme-component-background, 20%);
    }

    &-disabled + span {
      color: @dark-theme-text-color;
    }

    &-button-wrapper {
      color: lighten(@dark-theme-component-background, 20%);
      background-color: transparent;
      border-color: lighten(@dark-theme-component-background, 20%);

      &:hover,
      &:focus {
        color: @dark-theme-text-color;
      }

      &-checked {
        box-shadow: none;
        border-color: lighten(@dark-theme-component-background, 50%);
        color: lighten(@dark-theme-component-background, 50%);

        &:hover,
        &:focus {
          color: @dark-theme-text-color;
        }

        &:first-child {
          border-color: lighten(@dark-theme-component-background, 50%);
          color: lighten(@dark-theme-component-background, 50%);
        }
      }

      &:not(:first-child):before {
        background-color: lighten(@dark-theme-component-background, 20%);
      }

      &-checked:before {
        background-color: lighten(@dark-theme-component-background, 20%) !important;
      }
    }
  }

  & .@{class-prefix}-radio-group-link-bg-light.ant-radio-group {
    & .ant-radio-button-wrapper {
      color: lighten(@dark-theme-component-background, 20%);

      &:hover,
      &:focus {
        color: lighten(@dark-theme-component-background, 10%);
      }

      &.ant-radio-button-wrapper-checked {
        color: @dark-theme-text-color;
        background-color: @cyan-color;

        &:hover,
        &:focus {
          color: @white-color;
        }
      }
    }
  }

  & .ant-btn {
    background-color: transparent;
    border-color: @dark-theme-text-color;
    color: @dark-theme-text-color;

    &:hover,
    &:focus {
      color: @dark-theme-text-color;
      background-color: darken(@dark-theme-component-background, 8%);
      border-color: darken(@dark-theme-component-background, 8%);
    }

    &-primary {
      background-color: darken(@dark-theme-component-background, 8%) !important;
      border-color: darken(@dark-theme-component-background, 8%) !important;

      &:hover,
      &:focus {
        background-color: darken(@dark-theme-component-background, 12%) !important;
        border-color: darken(@dark-theme-component-background, 12%) !important;
      }

      &.disabled,
      &:disabled {
        background-color: darken(@dark-theme-component-background, 10%) !important;
        border-color: darken(@dark-theme-component-background, 10%) !important;
      }

      &:not([disabled]):not(.disabled):active,
      &:not([disabled]):not(.disabled).active {
        background-color: darken(@dark-theme-component-background, 12%) !important;
        border-color: darken(@dark-theme-component-background, 12%) !important;
      }
    }

    &-danger {
      color: @danger-color;
      background-color: transparent;
      border-color: @danger-color;

      &:hover,
      &:focus {
        color: @white-color;
        background-color: @danger-color;
        border-color: @danger-color;
      }
    }

    &-background-ghost {
      &.ant-btn-primary {
        background-color: darken(@dark-theme-component-background, 8%) !important;
        border-color: darken(@dark-theme-component-background, 8%) !important;
        color: @white-color !important;

        &:hover,
        &:focus {
          background-color: darken(@dark-theme-component-background, 12%) !important;
          border-color: darken(@dark-theme-component-background, 12%) !important;
          color: @white-color !important;
        }
      }
    }
  }

  & .@{class-prefix}-header-search {
    & .ant-select-selection {
      background-color: darken(@dark-theme-component-background, 10%);
      border-color: @border-color;
      color: @dark-theme-text-color;
      margin-left: -1px;

      &:focus {
        color: @dark-theme-text-color;
        background-color: darken(@dark-theme-component-background, 15%);
        border-color: @border-color;
      }
    }

    & .ant-select-arrow {
      color: @dark-theme-text-color;
    }
  }

  & .ant-upload {
    color: @dark-theme-text-color;

    &.ant-upload-drag {
      background-color: darken(@dark-theme-component-background, 3%);
      border-color: darken(@dark-theme-component-background, 3%);
      color: @dark-theme-text-color;

      & p.ant-upload-drag-icon .anticon {
        color: @dark-theme-text-color;
      }

      & p.ant-upload-text,
      & p.ant-upload-hint {
        color: @dark-theme-text-color;
      }

      &:not(.ant-upload-disabled):hover {
        border-color: @dark-theme-border-color;
      }
    }

    &-list-item a,
    &-list-item-info .anticon-loading,
    &-list-item-info .anticon-paper-clip {
      color: @dark-theme-text-color;
    }

    &-list-item:hover .ant-upload-list-item-info {
      background-color: lighten(@dark-theme-component-background, 5%);
    }

    &.ant-upload-select-picture-card {
      background-color: darken(@dark-theme-component-background, 3%);
      border-color: darken(@dark-theme-component-background, 3%);

      &:hover {
        border-color: @dark-theme-border-color;
      }
    }
  }

  & .ant-switch {
    color: @white-color;

    &-checked {
      background-color: darken(@dark-theme-component-background, 5%);
    }
  }

  & .@{class-prefix}-bg-grey {
    background-color: darken(@grey-5, 25%) !important;
  }

  & .ant-breadcrumb {
    color: darken(@white-color, 25%);

    &-separator,
    & > span:last-child {
      color: darken(@white-color, 25%);
    }
  }

  & .ant-dropdown {
    color: @dark-theme-text-color;

    &-menu {
      background-color: darken(@dark-theme-component-background, 8%);
    }

    &-menu-item,
    &-menu-submenu-title {
      color: @dark-theme-text-color;
    }

    &-menu-item:hover,
    &-menu-submenu-title:hover {
      background-color: lighten(@dark-theme-component-background, 2%);
    }

    &-menu-item .ant-dropdown-menu-submenu-arrow:after,
    &-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after {
      color: @dark-theme-text-color;
    }

    &-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
    &-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after {
      color: darken(@dark-theme-text-color, 20%);
    }
  }

  & .ant-steps-item {
    color: @dark-theme-text-color;

    &-finish .ant-steps-item-icon {
      background-color: @dark-theme-component-background;
      border-color: @dark-theme-text-color;

      & > .ant-steps-icon {
        color: @dark-theme-text-color;
      }
    }

    &-process .ant-steps-item-icon {
      background-color: lighten(@dark-theme-component-background, 5%);
      border-color: lighten(@dark-theme-component-background, 5%);

      & > .ant-steps-icon {
        color: @dark-theme-text-color;
      }
    }

    &-wait .ant-steps-item-icon {
      background-color: @dark-theme-component-background;
      border-color: lighten(@dark-theme-component-background, 10%);

      & > .ant-steps-icon {
        color: lighten(@dark-theme-component-background, 10%);
      }
    }

    &-finish > .ant-steps-item-content > .ant-steps-item-description,
    &-process > .ant-steps-item-content > .ant-steps-item-description,
    &-wait > .ant-steps-item-content > .ant-steps-item-description {
      color: darken(@dark-theme-text-color, 20%);
    }

    &-finish > .ant-steps-item-content > .ant-steps-item-title,
    &-process > .ant-steps-item-content > .ant-steps-item-title,
    &-wait > .ant-steps-item-content > .ant-steps-item-title {
      color: @dark-theme-text-color;
    }

    &-process > .ant-steps-item-content > .ant-steps-item-title:after,
    &-wait > .ant-steps-item-content > .ant-steps-item-title:after,
    &-process > .ant-steps-item-tail:after,
    &-error > .ant-steps-item-content > .ant-steps-item-title:after {
      background-color: @dark-theme-border-color;
    }

    &-finish > .ant-steps-item-content > .ant-steps-item-title:after,
    &-finish > .ant-steps-item-tail:after {
      background-color: @dark-theme-text-color;
    }

    &-error .ant-steps-item-icon {
      background-color: @dark-theme-component-background;
    }

    &-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
    &-finish > .ant-steps-item-tail:after,
    &-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background: darken(@dark-theme-primary-color, 10%);
    }

    &-process > .ant-steps-item-tail:after,
    &-wait > .ant-steps-item-tail:after {
      background-color: lighten(@dark-theme-component-background, 8%);
    }

    &-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
      background: lighten(@dark-theme-component-background, 8%);
    }
  }

  & .steps-content {
    border-color: @dark-theme-border-color;
    background-color: lighten(@dark-theme-component-background, 5%);
  }

  & .ant-collapse {
    // background-color: lighten(@dark-theme-component-background, 5%);
    // border-color: lighten(@dark-theme-component-background, 10%);
    // color: @dark-theme-text-color;
    background: @lite-theme-white-color;
    border: 1px solid #d9d9d9;
    &.@{class-prefix}-collapse-custom {
      background-color: transparent;

      & .ant-collapse-item {
        background-color: lighten(@dark-theme-component-background, 5%);
      }
    }

    & > .ant-collapse-item {
      border-color: lighten(@dark-theme-component-background, 10%);
    }

    & .ant-collapse {
      border-color: lighten(@dark-theme-component-background, 22%);

      & > .ant-collapse-item {
        border-color: lighten(@dark-theme-component-background, 22%);
      }
    }

    & > .ant-collapse-item > .ant-collapse-header {
      color: @heading-color;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: @heading-color!important;
    }

    &-content {
      color: @dark-theme-text-color;
      background-color: lighten(@dark-theme-component-background, 12%);
      border-top-color: lighten(@dark-theme-component-background, 10%);
    }

    & .ant-collapse-item-disabled > .ant-collapse-header,
    & .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
      color: darken(@dark-theme-text-color, 40%);
    }
  }

  & .ant-carousel {
    color: @dark-theme-text-color;

    & .slick-slide {
      background-color: lighten(@dark-theme-component-background, 5%);
    }
  }

  & .ant-tree {
    color: @dark-theme-text-color;

    & li .ant-tree-node-content-wrapper {
      color: @dark-theme-text-color;
    }

    & li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher),
    & li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
    & li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
      color: darken(@dark-theme-text-color, 40%);
    }

    & li .ant-tree-node-content-wrapper:hover,
    & li .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background-color: lighten(@dark-theme-component-background, 5%);
    }

    &-checkbox-wrapper:hover .ant-tree-checkbox-inner,
    &-checkbox:hover .ant-tree-checkbox-inner,
    &-checkbox-input:focus + .ant-tree-checkbox-inner {
      border-color: darken(@dark-theme-component-background, 5%);
    }

    &-checkbox-checked .ant-tree-checkbox-inner,
    &-checkbox-indeterminate .ant-tree-checkbox-inner {
      background-color: darken(@dark-theme-component-background, 5%);
      border-color: darken(@dark-theme-component-background, 5%) !important;
    }

    &.ant-tree-show-line li span.ant-tree-switcher {
      background: transparent;
      color: @dark-theme-text-color;
    }
  }

  & .ant-tag {

    &-checkable {
      color: @dark-theme-text-color;
    }

    &-checkable:not(.ant-tag-checkable-checked):hover {
      color: @white-color;
    }
  }

  & .ant-progress {
    &-text,
    &-circle .ant-progress-text {
      color: @dark-theme-text-color;
    }
  }

  & .ant-anchor {
    &-wrapper {
      background-color: transparent;
    }

    &-link-active > .ant-anchor-link-title {
      color: darken(@dark-theme-text-color, 10%);
    }
  }

  & .rdw-editor-wrapper {
    color: darken(@dark-theme-text-color, 60%);
    background-color: @component-background;

    & + textarea {
      color: darken(@dark-theme-text-color, 60%);
    }
  }

  & .@{class-prefix}-contact-item:not(:last-child) {
    border-bottom-color: @dark-theme-border-color;
  }

  .@{class-prefix}-draggable-icon {
    color: @dark-theme-text-color;

    &:hover,
    &:focus,
    &:active {
      color: @white-color;
    }
  }

  & .sweet-alert,
  & .@{class-prefix}-map-content {
    color: darken(@dark-theme-text-color, 60%);

    & h1, & h2, & h3, & h4, & h5, & h6, & .h1, & .h2, & .h3, & .h4, & .h5, & .h6 {
      color: darken(@dark-theme-text-color, 60%);
    }
  }

  & .@{class-prefix}-module-side {

    @media screen and (max-width: @screen-md-max) {
      background-color: @white-color;
    }
  }

  & .@{class-prefix}-module-side-header {
    border-bottom-color: @dark-theme-border-color;
    background-color: @dark-theme-component-background;
    color: @dark-theme-text-color;
  }

  & .@{class-prefix}-module-nav {

    & li .@{class-prefix}-link {
      color: @dark-theme-text-color;
    }

    & li .@{class-prefix}-link:hover,
    & li .@{class-prefix}-link:focus,
    & li .@{class-prefix}-link.active {
      color: @white-color;
    }
  }

  & .@{class-prefix}-module-box-header {
    background-color: @dark-theme-component-background;
    border-bottom-color: @dark-theme-border-color;

    & .@{class-prefix}-drawer-btn {
      @media screen and (max-width: @screen-md-max) {
        border-right-color: @dark-theme-border-color;
      }
    }
  }

  & .@{class-prefix}-module-box-content {
    background-color: @dark-theme-component-background;
  }

  & .@{class-prefix}-module-box-topbar,
  & .@{class-prefix}-module-list-item:not(:last-child) {
    border-bottom-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-toolbar-separator {
    border-left-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-module-sidenav {
    background-color: @dark-theme-component-background;
    border-right-color: @dark-theme-border-color;
  }

  & .rbc-event {
    background-color: lighten(@dark-theme-component-background, 20%);
  }

  & .rbc-event.rbc-selected {
    background-color: darken(@dark-theme-component-background, 10%);
  }

  & .rbc-slot-selection {
    background-color: lighten(@dark-theme-component-background, 10%);
  }

  & .rbc-toolbar button {
    color: @dark-theme-text-color;
    border-color: @dark-theme-text-color;
  }

  & .rbc-toolbar button:active,
  & .rbc-toolbar button.rbc-active,
  & .rbc-toolbar button:active:hover,
  & .rbc-toolbar button.rbc-active:hover,
  & .rbc-toolbar button:active:focus,
  & .rbc-toolbar button.rbc-active:focus,
  & .rbc-toolbar button:focus,
  & .rbc-toolbar button:hover {
    color: @dark-theme-primary-color;
    background-color: @dark-theme-text-color;
    border-color: @dark-theme-text-color;
  }

  & .rbc-month-view,
  & .rbc-time-view,
  & .rbc-today {
    background: @dark-theme-component-background;
  }

  & .rbc-date-cell {
    & .@{class-prefix}-link {
      color: @dark-theme-text-color;
    }

    & .@{class-prefix}-link:hover,
    & .@{class-prefix}-link:focus {
      color: @white-color;
    }
  }

  & .rbc-off-range-bg {
    background: lighten(@dark-theme-component-background, 10%);

    & .@{class-prefix}-link {
      color: darken(@dark-theme-text-color, 40%);
    }

    & .@{class-prefix}-link:hover,
    & .@{class-prefix}-link:focus {
      color: @dark-theme-text-color;
    }
  }

  & .@{class-prefix}-com-calendar-card {
    border-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-chat-module-box {
    background-color: @dark-theme-component-background;
  }

  & .@{class-prefix}-chat-sidenav {
    border-right-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-chat-sidenav-header {
    background-color: lighten(@dark-theme-component-background, 5%);
    border-bottom-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-chat-sidenav-content {
    background-color: @dark-theme-component-background;
  }

  & .@{class-prefix}-chat-sidenav-title {
    color: @dark-theme-text-color;
  }

  & .@{class-prefix}-chat-tabs-header {
    background-color: lighten(@dark-theme-component-background, 5%) !important;
  }

  & .@{class-prefix}-chat-user-item {
    &:not(:last-child) {
      border-bottom-color: @dark-theme-border-color;
    }

    &.active,
    &:hover {
      background-color: lighten(@dark-theme-component-background, 3%);
    }
  }

  & .@{class-prefix}-chat-info-des {
    color: @dark-theme-text-color;
  }

  & .@{class-prefix}-chat-main-header {
    border-bottom-color: @dark-theme-border-color;
    background-color: @dark-theme-component-background;
  }

  & .@{class-prefix}-chat-main-footer {
    border-top-color: @dark-theme-border-color;
    background-color: @dark-theme-component-background;
  }

  & .@{class-prefix}-chat-item {

    & .@{class-prefix}-bubble {
      background-color: lighten(@dark-theme-component-background, 2%);
      border-color: @dark-theme-border-color;
    }

    &.@{class-prefix}-flex-row-reverse .@{class-prefix}-bubble {
      background-color: lighten(@dark-theme-component-background, 5%);
    }
  }

  & .@{class-prefix}-error-code {
    color: @dark-theme-text-color;
    text-shadow: 10px 6px 8px rgba(255, 117, 117, 0.8);
  }

  & .@{class-prefix}-btn-yellow,
  & .@{class-prefix}-link.@{class-prefix}-btn-yellow {
    color: @grey-9 !important;
    background-color: @yellow-color !important;
    border-color: @yellow-color !important;

    &:hover,
    &:focus {
      color: @white-color !important;
      background-color: darken(@yellow-color, 10%) !important;
      border-color: darken(@yellow-color, 10%) !important;
    }

    &.disabled,
    &:disabled {
      background-color: @yellow-color !important;
      border-color: @yellow-color !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      color: @white-color !important;
      background-color: darken(@yellow-color, 10%) !important;
      border-color: darken(@yellow-color, 10%) !important;
    }
  }

  & .@{class-prefix}-package {
    background-color: @white-color;
    color: @grey-8;
  }

  & .@{class-prefix}-package-header {
    & > .@{class-prefix}-price {
      color: @white-color;
    }

    &.@{class-prefix}-text-white {
      color: @grey-8 !important;
    }
  }

  & .@{class-prefix}-customizer-item:not(:last-child) {
    border-bottom-color: @dark-theme-border-color;
  }

  & .ant-layout-sider-dark,
  & .gx-drawer-sidebar-dark {
    & .@{class-prefix}-sidebar-notifications {
      border-bottom-color: @dark-theme-border-color;
    }
  }

  & .ant-layout-sider-dark,
  & .gx-drawer-sidebar-dark {
    & .@{class-prefix}-app-nav {
      color: @dark-theme-text-color;
    }
  }

  & .@{class-prefix}-text-grey {
    color: @dark-theme-text-color !important;
  }

  & .@{class-prefix}-currentplan-col .@{class-prefix}-bg-dark {
    background-color: @dark-theme-text-color !important;
  }

  & .@{class-prefix}-chart-up {
    color: @green-color;
  }

  & .@{class-prefix}-bg-geekblue .@{class-prefix}-chart-up,
  & .@{class-prefix}-overlay-primary .@{class-prefix}-chart-up,
  & .@{class-prefix}-overlay-pink .@{class-prefix}-chart-down {
    color: @white-color;
  }

  & .@{class-prefix}-chart-down {
    color: @red-6;
  }

  .@{class-prefix}-text-black {
    color: @white-color !important;
  }

  & .@{class-prefix}-algolia-sidebar .ais-HierarchicalMenu {
    &-list li a {
      color: @dark-theme-text-color;

      &:hover,
      &:focus {
        color: @primary-color;
      }
    }

    &-count {
      color: @dark-theme-text-color;
    }
  }

  & .ais-Breadcrumb,
  & .ais-ClearRefinements,
  & .ais-CurrentRefinements,
  & .ais-GeoSearch,
  & .ais-HierarchicalMenu,
  & .ais-Hits,
  & .ais-Results,
  & .ais-HitsPerPage,
  & .ais-ResultsPerPage,
  & .ais-InfiniteHits,
  & .ais-InfiniteResults,
  & .ais-Menu,
  & .ais-MenuSelect,
  & .ais-NumericMenu,
  & .ais-NumericSelector,
  & .ais-Pagination,
  & .ais-Panel,
  & .ais-PoweredBy,
  & .ais-RangeInput,
  & .ais-RangeSlider,
  & .ais-RatingMenu,
  & .ais-RefinementList,
  & .ais-RefinementList-count,
  & .ais-SearchBox,
  & .ais-SortBy,
  & .ais-Stats,
  & .ais-ToggleRefinement,
  & .ais-RatingMenu-count,
  & .@{class-prefix}-algolia-category-title {
    color: @dark-theme-text-color;
  }

  & .ais-SearchBox-input,
  & .ais-RangeInput-input,
  & .ais-MenuSelect-select,
  & .ais-NumericSelector-select,
  & .ais-HitsPerPage-select,
  & .ais-ResultsPerPage-select,
  & .ais-SortBy-select,
  & .@{class-prefix}-link.ais-Pagination-link:hover,
  & .@{class-prefix}-link.ais-Pagination-link:focus {
    color: @dark-theme-primary-color;
  }

  & .@{class-prefix}-link.ais-Pagination-link--selected:hover,
  & .@{class-prefix}-link.ais-Pagination-link--selected:focus {
    color: @white-color;
  }

  & .ais-RangeInput-submit,
  & .ais-ClearRefinements-button,
  & .ais-CurrentRefinements-reset,
  & .ais-GeoSearch-redo,
  & .ais-GeoSearch-reset,
  & .ais-HierarchicalMenu-showMore,
  & .ais-InfiniteHits-loadMore,
  & .ais-InfiniteResults-loadMore,
  & .ais-Menu-showMore,
  & .ais-RefinementList-showMore {
    background-color: @dark-theme-text-color;
    color: @dark-theme-primary-color;

    &:focus,
    &:hover {
      background-color: @dark-theme-text-color;
    }
  }

  & .ais-Breadcrumb-link,
  & .ais-HierarchicalMenu-link,
  & .ais-Menu-link,
  & .ais-Pagination-link,
  & .ais-RatingMenu-link {
    color: @dark-theme-primary-color;

    &:focus,
    &:hover {
      color: @dark-theme-primary-color;
    }
  }

  & .ais-Pagination-item--selected .ais-Pagination-link {
    background-color: @primary-color;
    border-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-card-profile.@{class-prefix}-card-tabs .ant-card-head,
  & .@{class-prefix}-featured-item:not(:last-child) {
    border-bottom-color: @dark-theme-border-color;
  }

  & .@{class-prefix}-follower-list li,
  & .@{class-prefix}-follower-title {
    color: @dark-theme-text-color;
  }

  & .@{class-prefix}-header-horizontal-main .@{class-prefix}-header-notifications li > span {
    color: @dark-theme-text-color;
  }

  & .@{class-prefix}-profile-banner {
    background-color: darken(@dark-theme-secondary-color, 10%);
  }

  & .@{class-prefix}-text-primary {
    color: @cyan-color !important;

    &.@{class-prefix}-icon-white {
      color: @white-color !important;
    }
  }

  & .@{class-prefix}-link.@{class-prefix}-text-primary {
    &:hover,
    &:focus {
      color: @cyan-8 !important;
    }
  }

  & .@{class-prefix}-bg-primary {
    background-color: @cyan-color !important;
  }

  & .@{class-prefix}-link.@{class-prefix}-bg-primary {
    &:hover,
    &:focus {
      background-color: @cyan-8 !important;
    }
  }

  & .@{class-prefix}-border-primary {
    border-color: @cyan-color !important;
  }

  & .@{class-prefix}-btn-primary,
  & .@{class-prefix}-link.@{class-prefix}-btn-primary,
  & .btn-primary {
    background-color: @cyan-color !important;
    border-color: @cyan-color !important;

    &:hover,
    &:focus {
      background-color: @cyan-8 !important;
      border-color: @cyan-8 !important;
    }

    &.disabled,
    &:disabled {
      background-color: @cyan-color !important;
      border-color: @cyan-color !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-color: @cyan-8 !important;
      border-color: @cyan-8 !important;
    }
  }

  & .@{class-prefix}-btn-outline-primary,
  & .@{class-prefix}-link.@{class-prefix}-btn-outline-primary {
    color: @cyan-color !important;
    border-color: @cyan-color !important;

    &:hover,
    &:focus {
      color: @white-color !important;
      background-color: @cyan-color !important;
      border-color: @cyan-color !important;
    }

    &.disabled,
    &:disabled {
      color: @cyan-color !important;
    }
  }

  & .@{class-prefix}-badge-primary,
  & .@{class-prefix}-entry-title:before {
    background-color: @cyan-color !important;
  }

  & .@{class-prefix}-bg-white .@{class-prefix}-text-grey {
    color: @dark-theme-primary-color !important;
  }
}

//Custom css for lite theme

.lite-theme {

  .gx-layout-sider-dark {
    background-color: @lite-theme-menu-bg;
    .gx-layout-sider-header {
      background: #edecec;
    }
  }

  .ant-layout-header {
    background: #fefefe;
    box-shadow: 0 0 4px 4px rgb(0 0 0 / 8%);
  }

  .header-divider {
    background: transparent;
    border: 1px solid transparent;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: @lite-theme-lite-blue !important;
  }

  #pie-chart-holder, 
  #map-filter-holder,
  .ant-card-body {
    background: @lite-theme-white-color !important;
    .ant-card-head {
      background: transparent !important;
    }
  }

  .selectedChart, .ant-collapse {
    color: @lite-theme-lite-blue !important;
  }

  .gx-card .ant-card-head .ant-card-head-title {
    color: @lite-theme-lite-blue;
  }

  .prev-icon {
    background-color: @lite-theme-white-color;
    color: @dark-theme-text-color;
    border-color: transparent;
    :hover {
      background-color: #038fdd;
      border-color: #038fdd;
    }
  }

  .customize-column-btn {
    background-color: transparent !important;
  }

  .uhe-table {
    background: @lite-theme-white-color;
    margin-bottom: 5rem;
    border-radius: 6px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  }

  .uhe-table-header {
    margin-bottom: 0.5rem;
  }

  .uhe-table-td a {
    color: #038fde !important;
  }

  .uhe-table-head,
  .ant-table-column-title {
    font-size: @txt-3md;
    text-transform: uppercase;
    color: @lite-theme-white-color;
    white-space: nowrap;
    font-weight: @txt-normal;
    display: flex;
    justify-content: space-between;
  
    .icon,
    .ant-table .ant-table-tbody td {
      font-size: @txt-md;
    }
  
    #uhe-title {
      font-size: @txt-2xl;
    }
  
    .ant-card-head-title {
      color: @lite-theme-lite-blue !important;
    }
  
    .filter-btn {
      .reset-btn {
        color: #000;
        font-size: 15px;
        margin: auto;
      }
    }
  
    .ant-form-item {
      margin-bottom: 5px!important;
    }
  
    .ant-form-horizontal .ant-row {
      margin-bottom: 5px!important;
    }
  
    .sort-icon.icon {
      font-size: 12px;
      min-width: 12px;
      display: inline-block;
    }
  }

  .ant-table-thead > tr > th:hover,
  .ant-table-thead > tr > th {
    color: @lite-theme-white-color;
    font-size: 18px;
    font-weight: normal;
    background-color: #5cc9dd !important;
    border-right: 1px solid @lite-theme-white-color!important;
    padding-left: 10px;
  }

  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
    color: @white-color;
  }

  .ant-table-thead>tr>th {
    color: #535353;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    transition: background .3s ease;
  }

  .table-input,
  .ant-cascader-picker {
    background-color: @lite-theme-white-color !important;
    border-color: 1px solid #e8e8e8 !important;
  }

  .uhe-table-td a {
    color: @dark-theme-text-color;
  }

  .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background: transparent;
  }

  .ant-table-footer {
    padding: 16px 16px;
    color: #535353;
    background: #fafafa;
  }

  .gx-sub-popover li:not(:last-child) {
    align-items: center;
    display: flex;
  }

  // Titles
  .uhe-title {
  .title-txt(uppercase);
  }

  .page-title {
    font-size: @txt-2xl;
  }

  .gx-card {
    .ant-card-head {
      .ant-card-head-title {
        white-space: normal;
        font-weight: @txt-normal;
        color: @lite-theme-lite-blue;
      }
    }
  }

  // Topbar
  .topbar-title {
    color: @lite-theme-lite-blue;
    text-transform: @txt-up;
    font-size: @txt-3lg;
    text-align: center;
  }

  // Sidenav
  .gx-app-sidebar {
    .gx-icon-btn {
      transition: .3s;
      &:hover {
        background: @sidenav-toggle;
      }
    }
  }

  .gx-layout-sider-dark {
    .gx-layout-sider-header {
      background: @top-sidenav;
    }
  }

  .gx-avatar-name {
    font-size: @txt-2md;
    color: @sidenav-item;
  }
  
  .submenu-item .ant-menu-submenu-title {
    font-size: @txt-md!important;
  }
  
  .ant-menu-inline {
    .ant-menu-submenu-title {
      font-size: @txt-lg;
    }
    .ant-menu-item {
      font-size: @txt-md;
    }
  }

  .ant-menu {
    .ant-menu-dark {
      .ant-menu-item > a {
        color: @sidenav-item;
      }
    }
  }
  
  // Filters
  .filter-inputs {
    display: flex;
    .ant-cascader-picker:not(:last-child),
    .ant-select {
      margin-right: 15px;
    }
  }
  
  .reset-btn {
    margin: auto;
  }
  
  .filter-btn {
    margin-top: 1rem;
    text-align: center;
    .reset-btn {
      .reset();
    }
  }

  // Tables
  .gx-card {
    .ant-card-head {
      .ant-card-head-title {
        font-size: @txt-3lg;
        text-transform: none;
      }
    }
  }

  .uhe-table {
    background: @lite-theme-white-color;
    margin-bottom: 5rem;
    border-radius: 6px;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
  }

  th,
  td {
    white-space: nowrap;
  }

  .uhe-table-header {
    h2,
    p {
      margin: 0;
    }
  }

  .mandatory-field::before {
    display: inline-block;
    margin-right: 4px;
    color: @lite-theme-red-icon;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .uhe-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
    .uhe-title {
      font-size: @txt-2lg;
    }
  }

  .uhe-table-head,
  .ant-table-column-title {
    font-size: @txt-3md;
    text-transform: uppercase;
    color: @lite-theme-white-color;
    white-space: nowrap;
    font-weight: @txt-normal;
    display: flex;
    justify-content: space-between;

    .icon,
    .ant-table .ant-table-tbody td {
      font-size: @txt-md;
    }

    #uhe-title {
      font-size: @txt-2xl;
    }

    .ant-card-head-title {
      color: @lite-theme-lite-blue;
    }

    .filter-btn {
      .reset-btn {
        color: #000;
        font-size: 15px;
        margin: auto;
      }
    }

    .ant-form-item {
      margin-bottom: 5px!important;
    }

    .ant-form-horizontal .ant-row {
      margin-bottom: 5px!important;
    }

    .sort-icon.icon {
      font-size: 12px;
      min-width: 12px;
      display: inline-block;
    }
  }

  .uhe-table-td {
    display: flex;
    justify-content: space-between;
  }
  
  .ant-table-thead > tr > th:hover,
  .ant-table-thead > tr > th {
    color: @lite-theme-white-color;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: normal;
    background-color: #5cc9dd !important;
    border-right: 1px solid @lite-theme-white-color;
  }
  
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 10px;
    overflow-wrap: break-word;
  }
  
  .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
    color: @lite-theme-white-color;
  }
  
  .ant-table-thead > tr > th .ant-table-column-sorter {
    display: none;
  }
  
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .react-resizable-handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
  }
  
  .ant-table-pagination {
    &.ant-pagination {
      float: none;
      text-align: center;
    }
  }
  
  .ant-table-pagination.ant-pagination {
    float: none;
    text-align: center;
  }
  
  .configuration {
    /* Filter Inputs */
    .filter-inputs {
      .ant-cascader-picker {
        margin-right: 15px;
      }
      display: inline-flex;
    }
    /* Reset Button */
    .customers-filter-box {
      .filter-btn {
        display: inline-flex;
        text-align: left;
      }
    }
  }
  
  .ant-select {
    width: 100%;
  }

  .ant-btn {
    margin-bottom: 0px;
  }
  
  /* Send Button */
  .sendButton {
    width: 100%;
    background: #CDE188 !important;
    color: @lite-theme-main-tezt-color !important;
    border-color: transparent !important;
  }
  
  .sendButton2{
    width: 100%;
    background: #CDE188!important;
    color: @lite-theme-main-tezt-color !important;
    border-color: transparent!important;
  }
  
  .sendButton:hover{
    background-color: #A5C04F!important;
    color: @lite-theme-main-tezt-color!important;
    border-color: transparent!important;
  }
  
  .sendButton{
    margin-left: 10px;
  }
  
  .system .save-icon {
    margin-bottom: 15px;
  }

  .iObserver-btn, .providers-table-btn {
    border: 1px solid transparent!important;
    background: transparent!important;
    padding: 10px!important;
    height: 0px!important;
    display: flex;
      align-items: center;
  }
  
  .upload {
    width: 100%;
  }
  
  .ant-upload.ant-upload-select {
    width: 100%;
  }
  
  .icon-sweet-alert {
    color: #3490CD;
  }
  
  .icon-error {
    color: @lite-theme-main-red-color;
  }
  
  .action-butons {
    justify-content: space-around;
    .edit-icon {
      margin-right: 15px !important;
    }
  }
  
  .system .check-icon {
    font-size: 17px!important;
    color: #a3c928!important;
  }
  
  .system .icon-close-circle {
    font-size: 17px !important;
    color: @lite-theme-main-red-color !important;
  }
  
  .icon-trash {
    color: @lite-theme-main-red-color !important;
  }

  .icon-reset-password {
    color: #3490CD !important;
  }
  
  .icon-forgot-password {
    color: #A5C04F !important;
  }
  
  .ant-card-head-title {
    white-space: normal;
    font-weight: @txt-normal;
    color: @lite-theme-lite-blue;
    font-size: @txt-3lg;
  }
  
  .check-icon {
    color: #a3c928;
  }
  
  .tableForm .prev-icon {
    display: none;
  }
  
  //Error Message
  .errorMessage {
    color: #CA0B00;
  }
  
  .custom-error-message {
    color: @lite-theme-main-red-color;
  }
  
  .action-butons .ant-btn {
    height: auto;
    padding: 0 0;
    border-width: 0;
    margin-bottom: 5px;
    .icon-close-circle {
      color: @lite-theme-main-red-color !important;
    }
  }

  .updateButton {
    background: @lite-theme-green-icon;
  }
  
  .updateButton:hover {
    background-color: #A5C04F !important;
    color: #000;
    border-color: transparent;
  }
  
  .ant-btn {
    i {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .ant-select {
    width: 100%;
  }
  
  .buttons-container {
    display: flex;
  }
  
  .page-icons {
    color: #000;
    font-size: 1.1rem;
    padding: 5px 10px 5px 10px !important;
    border-radius: 50%;
    cursor: pointer;
  }

  .page-icon-plus {
    display: flex;
    align-items: center;
    background-color: @lite-theme-green-icon !important;
    border-radius: 20px;
    &:hover {
      background-color: #A5C04F !important;
      color: #000;
      border-color: transparent;
    }
  }
  
  .page-icon-bulk-upload {
    display: flex;
    align-items: center;
    background-color: #d3c014 !important;
    border-radius: 20px;
    margin-left: 10px;
    &:hover {
      background-color: #c4b216 !important;
      color: #000;
      border-color: transparent;
    }
    span {
      margin-left: 5px;
    }
  }
  
  .bulk-upload-description-cell {
    white-space: inherit;
  }
  
  .upload-header {
    display: flex;
    justify-content: flex-start;
    &-download {
      align-items: center;
      margin-left: 10px;
    }
  }
  
  .upload-header-message {
    margin-left: 10px;
  }

  .downloadBtn {
    margin-left: 20px
  }
  
  .icon-add, .icon-upload {
    display: flex;
    align-items: center;
  }
  
  .save-row {
    color: #a3c928;
    font-size: 15px!important;
    border: 1px solid transparent!important;
    background: transparent!important;
    padding: 0px!important;
    height: 0px!important;
    justify-content: center;
  }
  
  .action-btns {
    align-items: center;
    justify-content: space-around;
    text-align: center;
    display: flex;
  }

  .page-icon-export {
    display: flex;
    align-items: center;
    height: 30px;
    background-color: #FCD270 !important;
    margin-left: 10px;
    border-radius: 50px;
    .anticon-download {
      color: #000 !important;
    }
    &:hover {
      background-color: #ddb65b !important;
      color: #000;
      border-color: transparent;
    }&:focus {
      color: #000;
    }
  }
  
  .page-icon-edit {
    background: #72d2e4 !important;
    &:hover {
      background-color: #5ec1d4 !important;
      color: #000;
      border-color: transparent;
    }
  }
  
  .ant-cascader-picker .anticon.anticon-close-circle.ant-cascader-picker-clear {
    background-color: @white-color;
  
    svg {
      color: @grey-6;
    }
  }
  
  .page-icon-delete {
    rotate: 90deg;
    background: #ee66a4;
    &:hover {
      background-color: #D8478A !important;
      color: #000;
      border-color: transparent;
    }
  }

  // Footer
.lite-theme .gx-layout-footer-content {
  text-align: center;
}

//Save success message

.lite-theme .ant-message {
  bottom: 50px;
  top: 0;
}
}

// endddd


// Media Queries

.lite-theme .gx-header-notifications {
  @media @large-screen-down {
    margin-left: auto;
  }
}

.lite-theme .top-filter-popup ul.ant-cascader-menu {
  max-height: 40vh;
}

.lite-theme .filterDropdowns {
  .ant-row-flex {
    margin:  0 0 10px 0;
    align-items: center;
  }
  .ant-row-flex.clearBtn {
    margin:  40px 0 10px 0;
  }
}

.lite-theme .ant-form-item-label {
  text-align: left;
  line-height: 17px;
  white-space: normal;
}

.lite-theme .lite-theme .editNameInputRow {
  margin:  0 0 10px 0;
}

// fix for Form.Item container
.lite-theme .ant-form-item-control-input-content {
  padding-left: 15px;
  padding-right: 15px;
}

// Menu.ItemGroup
.lite-theme .ant-layout-sider .ant-menu-item-group-title {
  padding-top: 10px;
  margin-left: -5px;
}

.lite-theme .group-item {
  font-size: 16px;
  color: @lite-theme-white-color;
  .icon {
    margin-right: 12px;
  }
}

.lite-theme .ant-menu-dark .ant-menu-item > a {
  margin-left: 5px;
  color: #038fdd;
}

// small fix for clear filters button on dashboard
.lite-theme .dashboard .reset-btn {
  line-height: 17px;
  :hover {
    background: transparent !important;
  }
}

.lite-theme .ar-full-layout {
  direction: rtl;

  // fix for icon in language lelector
  .gx-language {
    .flag {
      margin-left: 5px;
    }
  }
  .save-btn-container {
    text-align: left!important;
  }

  // languages list
  .gx-popover-lang-scroll {
    // remove native vertical scroll bar
    > div:first-child {
      overflow-y: auto !important;
    }

    // fix languages list
    .flag.gx-mr-2 {
      margin-left: 0.5rem !important;
      margin-right: 0 !important;
    }

    // fix for japanese flag icon
    .gx-sub-popover {
      margin-right: -5px;
    }
  }

  // top filters
  .listings-top-filter {
    span.ant-cascader-picker:not(:last-child) {
      margin-left: 15px;
      margin-right: 0;
    }
  }

  // sidebar
  .gx-sidebar-content {
    // username field
    .gx-sidebar-notifications {
      .gx-avatar-name .icon.gx-ml-2 {
        margin-left: 0 !important;
        margin-right: 0.5rem !important;
      }
    }

    .gx-layout-sider-scrollbar {
      > div:first-child {
        overflow-y: hidden!important;
      }
    }

    // sidebar menu
    li.ant-menu-item-group {
      .ant-menu-item-group-title .group-item .icon {
        margin-right: 0;
        margin-left: 12px;
      }

      // sidebar sub-menu
      ul.ant-menu-item-group-list {
        .ant-menu-submenu-title .icon {
          margin-right: 0;
          margin-left: 20px;
        }
        .ant-menu-item a .icon {
          margin-left: 12px;
        }

        // sidebar submenu with items (open/closed)
        .ant-menu-submenu {
          .ant-menu-submenu-title {
            padding-right: 40px !important;

            .ant-menu-submenu-arrow {
              left: auto;
              right: 22px;
            }

            .icon-extra-components {
              margin-right: 20px;
            }
          }
        }

      }
    }
  }

  // buttons fix
  .page-icon-export {
    margin-left: 0;
    margin-right: 10px;
  }

  // pagination in bottom of list screens (Organization, Customers, etc...)
  .ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination {
    float: none;
  }
}
// Style for Japanese language


.lite-theme .ja-full-layout {
  .gx-card .ant-card-head {
    .ant-card-head-title {
      font-size: 1rem;
    }
  }
  .ant-card {
    font-size: 13px;
  }
}

@btn-padding-base: 0 15px;
@btn-padding-lg: 0 15px;
@btn-padding-sm: 0 15px;

.lite-theme .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.lite-theme .unit-card {
  margin: 0 auto;
  text-align: center;
}

.lite-theme .ant-row {
  margin-bottom: 10px;
}

.lite-theme .ant-form-item-label {
  padding: 10px !important;
}

.lite-theme .logout-button {
  border: 0;
  padding: 0;
  color: #45a1e5;
  height: 15px;
  display: flex;
  align-items: center;

}

.lite-theme .delete {
  color: @lite-theme-main-red-color;
  display: inline-block;
}

.lite-theme .uhe-table-td {
  display: flex;
  align-items: center;
}

.lite-theme .delete {
  margin-bottom: 0px!important;
}

.lite-theme .domainList .save-icon {
  display: none;
}

.lite-theme .reset-logo-image {
  width: 275px;
}

.lite-theme .reset-password-wrapper {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  .ant-form-item-control-input-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.lite-theme .reset-form {
  width: 100%;
}

.lite-theme .reset-submit-btn {
  width: 33.33%;
  background-color: #007cc2;
  color: @lite-theme-white-color;
}

.lite-theme .reset-submit-btn:hover, .reset-submit-btn:focus {
  background-color: #006ba7;
  color: @lite-theme-white-color;
}

.lite-theme .gx-layout-sider-scrollbar > div:first-child {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  margin-bottom: 0 !important;
}

.lite-theme .gx-layout-sider-scrollbar > div:first-child::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.lite-theme .changePassword .ant-form-item-control-input-content {
  padding-right: 7px!important;
}

@media only screen and (max-device-width: 991px) {
  .gx-avatar-row {
    margin-bottom: 0.3rem !important;
    .gx-avatar-name {
      font-size: 1.02rem;;
      color: #262626;
      margin: -20px;
    }
  }
  .gx-d-block {
    margin-left: -15px;
  }

  .upload-btn {
    font-size: 11.3px;
  }

  .gx-header-notifications > li:not(:last-child) {
    margin-right: 25px;
  }
}

.lite-theme .bedcarts, .reporting {
  font-size: @txt-md!important;
  text-transform: none!important;
}

.lite-theme .ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
  padding-left: 48px!important;
}

.lite-theme .ant-layout-sider .ant-menu-inline .ant-menu-item {
  padding-left: 38px!important;
}

// For UI in Organization/Edit/Add page //

.lite-theme .ant-form-item-control-input-content {
  padding-left: 0;
}

.lite-theme .align-row-city {
  display: flex;
}

.lite-theme .align-item-city{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  & label {
    display: flex;
    justify-content: flex-start;
  }
}

.lite-theme .align-item-city-form{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  & label {
    display: flex;
    justify-content: flex-start;
  }
  display: inline-block;
  margin: 0 0px;
}

.lite-theme .align-item-city-input{
  width: 37%;
}

.lite-theme .align-item-city-select{
  width: 20%;
}

.lite-theme .ant-btn-primary span {
  color: @lite-theme-white-color !important;
}

.lite-theme .nav-slider,
.lite-theme .nav-slider-collapsed {
  position: absolute;
  top: @nav-slider-top;
  z-index: 10;
  right: @nav-slider-right;
}


.lite-theme .gx-sidebar-content .nav-slider,.gx-sidebar-content .nav-slider-collapsed {
  display: none;
}

.lite-theme .gx-sidebar-content:hover .nav-slider,.gx-sidebar-content:hover .nav-slider-collapsed {
  display: initial;
}

.lite-theme .logo-sm {
  margin-right: auto;
}

.lite-theme .track-horizontal {
  background-color: #6eacdd;
  color: #6eacdd;
}

.lite-theme .thumb-vertical {
  background-color: #6eacdd;
  color: #6eacdd;
}

.lite-theme ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none !important;
}

.lite-theme .ant-menu-submenu-arrow {
  display: none !important;
}

.lite-theme .ant-menu-submenu-open > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none !important;
}

.lite-theme .reporting-submenu {
  padding-left: 0px !important;
}

.lite-theme .uhe-stats {
  margin-left: auto;
}

.lite-theme .reporting-submenu.ant-menu-submenu.ant-menu-submenu-inline  div.ant-menu-submenu-title {
  padding-left: 51px !important;
}

.lite-theme .reporting-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline li.ant-menu-item.ant-menu-item-only-child {
  padding-left: calc(51px + 5px) !important;
}

.lite-theme .reporting-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline li a{
  color: #038fde;
}

.lite-theme .reporting-submenu ul.ant-menu.ant-menu-sub.ant-menu-inline li.ant-menu-item-selected  a{
  color: @lite-theme-green-icon;
}

.lite-theme .ant-layout-sider {
  overflow: unset;
}

.lite-theme .gx-sidebar-notifications {
  position: relative;
}

.lite-theme .menu-msg {
  display: initial;
}

.lite-theme .menu-msg-collapsed{
  display: none;
}

.lite-theme .permisssion {
  .ant-card-body {
    padding: 24px!important;
  }
  .ant-col {
    margin-right: 5px;
  }
}

.lite-theme .userManagement .ant-table-cell {
  text-align: center;
}
.lite-theme .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: auto!important;
  padding: 0px 11px!important;
}

.lite-theme .permissionRole .ant-form-item {
  align-items: center!important;
}

.lite-theme .permissionRole .ant-form-horizontal .ant-row {
  margin-left: 0px!important;
  margin-right: 0px!important;
}


.lite-theme .my-btn {
  width: 100%;
  background: #CDE188 !important;
  color: @lite-theme-main-tezt-color !important;
  border-color: transparent!important;
  height: 40px;
}

.lite-theme .asdasd {
  font-weight: bold!important;
}

.lite-theme .boldedMessage {
  font-weight: bold!important;
  color: red!important;
}

.lite-theme .permission-table {
  width: 100%;
}

.lite-theme .ant-card-head {
  padding: 0 10px!important;
}
.lite-theme .hideReporting{
  display: none;
}

.lite-theme .videos-fields{
  margin: 6px !important;
}

.lite-theme .ant-btn-sm {
  line-height: 18px!important;
}

.lite-theme .ant-popover-buttons .ant-btn-sm:first-child {
  background-color: #038fde;
  color: @lite-theme-white-color !important;
  border-color: #038fde!important;
  height: 34px;
  span {
    color: @lite-theme-white-color !important;
    font-size: 13px;
  }
}

.lite-theme .ant-popover-buttons .ant-btn-sm:nth-child(2) {
  background-color: transparent !important;
  border-color: @lite-theme-red-icon !important;
  height: 34px;
  span {
    color: @lite-theme-red-icon !important;
    font-size: 13px;
  }
}

.lite-theme .ant-table-selection-column {
  width: 7%;
}

.lite-theme .bulk-actions-table {
  display: flex;
  margin: 20px auto;
  justify-content: center;
}

.lite-theme .ant-table-column-sorters {
  padding: 0;
}

.lite-theme .table-filter-holder {
  padding: 16px;
}

.lite-theme .form-item-row {
  display: flex;
  align-items: center;
  margin: 0 !important;
  .ant-form-item-label {
    display: flex;
  }
  .ant-form-item-label[class*="ant-col-"] {
    text-align: initial;
  }
}

.lite-theme .bulk-action-page {
  .save-icon {
    display: none!important;
  }
  .uhe-table {
    margin-bottom: 0px;
  }
  .reset-btn {
    display: none;
  }
  .permission-table {
    margin: 10px auto;
    width: 60%;
  }
}

.lite-theme .refresh-btn {
  margin-bottom: 15px;
}

.lite-theme .bulk-action-page {
  .filter-inputs {
    .ant-cascader-picker:first-child {
      display: none;
    }
  }
}

.lite-theme .bulk-action-wrapper {
 margin-left: 4px;
}

.lite-theme .outter-list {
  list-style: initial;
}

.lite-theme .inner-list {
  list-style: revert;
}

.lite-theme .ant-table-cell .icon-data-display {
  color: @lite-theme-main-red-color;
}

.lite-theme .device-icon {
  margin-right: 5px;
}

.lite-theme .table-popover {
  text-align: center;
}

.lite-theme .ant-form-item-label[class*="ant-col-"] {
  text-align: initial!important;
}

.lite-theme .ant-form-horizontal .ant-row {
  margin-left: 0!important;
  margin-right: 0!important;
}

.lite-theme .status-online {
margin-bottom: 17px;
}

.lite-theme .main-row {
  padding-left: 11px;
}

.lite-theme .call-btn {
  margin-top: 10px;
}

.lite-theme .fixed-value-width {
  width: 135px;
  margin-right: 10px;
}

.lite-theme .act-btn {
  height: 18px;
  justify-content: center;
  display: flex;
  align-items: center;
  line-height: 28px;
}

.lite-theme .section-titles {
  white-space: normal;
  font-weight: 400;
  color: @lite-theme-lite-blue;
}

.lite-theme .doc-items {
  .ant-form-item-control-input {
    min-height: 40px;
  }
}

.lite-theme .audit-info:last-child {
  margin-bottom: 0;
}

.lite-theme .notification-message {
  .message {
    white-space: initial;
  }
}

.lite-theme .support-icon {
  font-size: 15px !important;
  margin-right: 12px;
}

.lite-theme .ant-menu-item .iobserver-icon {
  margin-right: 12px;
}

.lite-theme .ant-table-pagination-right {
  justify-content: center;
}

.lite-theme .removeToken {
  height: 40px;
  font-size: 18px;
  text-align: center;
}

.lite-theme .removeToken p {
  margin-top: 20px;
}

.lite-theme .removeToken span {
  color: #6eacdd;
  cursor: pointer;
}

.lite-theme .delPicure {
  justify-content: end;
}

.lite-theme .devImg {
  display: flex;
  align-items: center;
  margin-top: 5px!important;
  justify-content: space-between;
}

.lite-theme .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon {
  margin-right: 26px;
}

.lite-theme .anticon-question-circle {
  font-size:  24px!important;
  margin-top: 0px;
}

.lite-theme .ant-popover-content {
    margin: 0 auto!important;
    width: 100%!important;
}

.lite-theme .upload_document {
  width: 100%;
}

.lite-theme .upload-btn {
  width: 100%;
}

.lite-theme .jamf-support .ant-form-item-control-input-content {
  padding-right: 0px!important;
}

.lite-theme .teamviewer-credentials {
  margin-left: 10px;
}

.lite-theme .ant-collapse {
  width: 100%!important;
  margin-bottom: 20px;
}

.lite-theme .margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.lite-theme .margin-left-8 {
  margin-left: 8px !important;
}

.lite-theme .margin-right-10 {
  margin-right: 10px !important;
}

.lite-theme .padding-right-0 {
  padding-right: 0 !important;
}

.lite-theme .bulkUpload__instructions .ant-form-item-control-input-content > span {
  display: flex !important;
  align-items: center;
}

.lite-theme .bulkUpload__instructions .ant-upload-list-text .ant-upload-span, .ant-upload-list-picture .ant-upload-span {
  display: flex !important;
}

.lite-theme .product-documentation {
  white-space: nowrap;
  text-overflow: ellipsis !important;
}

.lite-theme .upload-box{
  width:100% !important;
}

.lite-theme .overflow-wrap-break-word {
  overflow-wrap: break-word;
}

.lite-theme .inCall-icon {
  color: #070782;
}

.lite-theme .overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.lite-theme .download-csv-button {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #038fde;
  cursor: pointer;
  display: inline-block!important;
  align-items: center!important;
  justify-content: space-between;
  margin: 0 auto;
}

.lite-theme .download-button {
  border-color: transparent;
  color: #038fde;
}

.lite-theme .download-button:hover {
  background-color: transparent;
  border-color: transparent;
}

.lite-theme .download-icon {
  font-size: 15px;
}

.lite-theme .height-100-percent {
  height: 100%;
}

.lite-theme .ant-table .ant-table-container::before, .ant-table .ant-table-container::after{
  display: none;
}

.lite-theme .timestamps > div {
  padding: 0 20px;
  margin-bottom: 10px;
}

.lite-theme .timestamps > div:last-child {
  margin-bottom: 0px;
}

.lite-theme .horizontal-vertical-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lite-theme .battery {
  margin-top: 19px;
}

.lite-theme .ant-radio-wrapper {
  white-space: normal !important;
  margin:5px;
}

.lite-theme .time-zone {
  width: 37%;
}

.lite-theme .organization-row {
  padding: 0!important;
}

.lite-theme .commands-number-title {
  font-size: @txt-3lg;
}

.lite-theme .commands-number {
  color: @lite-theme-lite-blue;
}

.lite-theme .filter-title {
  color: @lite-theme-lite-blue;
}
.lite-theme .connectivity-icon {
  width: 15px;
  height: 15px;
  margin: 0 !important;
}

.connectivity-icon-fail {
  color: @lite-theme-main-red-color;
}

.lite-theme .connectivity-icon-success {
  color: @lite-theme-green-icon;
}

.lite-theme .console-btn {
  height: 36px;
}
.lite-theme .numberColumns {
  padding-left: 8px!important;
}

.lite-theme .device-header {
  margin-top: 20px;
}
.lite-theme .viewButton {
  width: 100px!important;
  padding: 10px 20px;
  border-radius: 3px;
  margin-left: 0px;
}

.lite-theme .display-name-container {
  display: flex;
  align-items: center;
  max-width: 220px;
}

.lite-theme .display-name {
  max-width: 210px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
}

.lite-theme .action-button button {
  width: 100%;
  height: 100%;
}

.lite-theme .password-btn-wrapper {
  padding-left: 20px;
}

.lite-theme .button-password {
  align-items: center;
}

.lite-theme .button-password i {
  padding: 5px;
.command-btn--green,
.command-btn--green:hover,
.command-btn--green:focus {
  background: #CDE188;
  color: @lite-theme-main-tezt-color;
  border: none;
}
}

.lite-theme .show-IP {
  display: flex;
  align-items: center;
}

.lite-theme .bulk-actions-card {
  .ant-card-body {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.lite-theme .verify-IP {
  display: flex;
  flex-direction: row;
}

.lite-theme .custom-mobile-patient-wrapper {
  margin-top: 10px;
}

.lite-theme .ant-table.ant-table-bordered > .ant-table-container,
.lite-theme .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
  border: 1px solid #e8e8e8 !important;
  color: @lite-theme-main-tezt-color;
}

.lite-theme .ant-table-tbody {
  background-color: @lite-theme-white-color;
}

.lite-theme .view-container {
  border-color: transparent;
}

.lite-theme .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.lite-theme .ant-menu-dark .ant-menu-item-selected > a {
  color: @lite-theme-green-icon !important;
}

.lite-theme .ant-menu-dark .ant-menu-item > a:hover {
  color: @lite-theme-green-icon !important;
}

.lite-theme .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:hover {
  background-color: transparent !important;
}

.lite-theme .ant-menu-item:hover {
  background-color: #0386c2 !important;
}

.lite-theme .ant-btn-circle {
  background-color: #0386c2 !important;
  :hover {
    background-color: #0386c2 !important;
  }
}

.lite-theme .ant-table {
  color: @lite-theme-white-color !important;
}

.lite-theme .ant-select-dropdown,
.lite-theme .ant-dropdown-menu {
  background-color: @lite-theme-menu-bg;
  box-shadow: none;
}

.lite-theme .ant-card-head {
  background: @lite-theme-white-color !important;
}

.lite-theme .ant-card {
  background-color: @lite-theme-white-color !important;
}

.lite-theme .ant-checkbox-wrapper {
  color: @lite-theme-white-color;
}

.lite-theme .ant-radio-button-wrapper {
  background-color: transparent !important;
  color: @lite-theme-white-color;
  :hover {
    color: @lite-theme-white-color;
  }
}

.lite-theme .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: transparent;
  color: #0092D7;
  border-color: #0092D7;
}

.lite-theme .grid-view-card {
  background-color: #f5f5f5;
}

.lite-theme .grid-view-card__header, 
.lite-theme .grid-view-card__room,
.lite-theme .grid-view-card__room > a {
  color: @lite-theme-main-tezt-color;
}

.lite-theme .ant-cascader-picker, .lite-theme .ant-input, .lite-theme .ant-select:not(.ant-select-customize-input) .ant-select-selector, .lite-theme .ant-upload.ant-upload-select-picture-card, .lite-theme .ant-checkbox-inner, .lite-theme .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .lite-theme .ant-input[disabled], .lite-theme .colorPickerWrapper span {
  background: @lite-theme-white-color;
  border-color: 1px solid #e8e8e8 !important;
  color: #c8c8c8;
}

.lite-theme .ant-input[disabled] {
  background: #f5f5f5 !important;
}

.lite-theme .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: @lite-theme-white-color;
}

.lite-theme .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td {
  background-color: @lite-theme-white-color;
}

@media screen and (max-width: @screen-md-max) {
  .lite-theme .@{class-prefix}-popover-horizantal {
    & .@{class-prefix}-search-bar input[type="search"] {
      background: none !important;

      &:focus,
      &:hover {
        .box-shadow(none);
      }
    }

    & .ant-btn {
      background-color: transparent;
      border-color: transparent;

      &:hover,
      &:focus {
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

@media screen and (max-width: @screen-sm-max) {
  .lite-theme .@{class-prefix}-table-responsive .ant-table {
    border: @dark-theme-border-color;

    &.ant-table-bordered .ant-table-footer {
      border-top-color: @dark-theme-border-color;
    }
  }

  .lite-theme .ant-card-bordered.@{class-prefix}-card-full .@{class-prefix}-table-responsive .ant-table {
    border-bottom-color: @dark-theme-border-color;
  }

  .lite-theme .@{class-prefix}-timeline-center .@{class-prefix}-timeline-inverted:before {
    border-left-color: @dark-theme-border-color;
  }
}

@media screen and (max-width: (@screen-xs-max - 100px)) {
  .lite-theme .ant-input-group.ant-input-group-compact {
    & .@{class-prefix}-border-lt-xs {
      border-left-color: @dark-theme-border-color !important;
    }
  }
}
