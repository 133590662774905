@import './uheVariables.less';

.ant-table-thead,
.ant-table-tbody {
  tr {
    th, td {
      padding: 0px 6px;
    }
  }  
}

.uhe-table-td {

  .version {
    display: flex;
    align-items: center;
    .icon {
    }

    .icon-error {
    }
    .icon-uncheck-squire{
      color: @green-icon;
    }
    .icon-circle{
      color: @magenta-icon;
    }
    .icon-triangle{
      color: @yellow-icon;
        }

    .icon-breadcrumb {
      color: @yellow-icon;
      display: flex;
      align-items: center;
    }
  }

  .search-icon {
    margin-left: 10px;
    display: inherit;

    .icon {
      margin: auto;
      height: 15px;
    }
  }

  .maint-mode, .in-call, .status {
    margin: auto;

    .icon.grey {      
      color: @grey-icon;
    }
    .icon.green {      
      color: @green-icon;
    }
    .icon.yellow {      
      color: @yellow-icon;
    }
    .icon.red {
      color: @magenta-icon;
    }

  }
}

.lite-theme .uhe-table-td {

  .version {
    display: flex;
    align-items: center;
    .icon {
    }

    .icon-error {
    }
    .icon-uncheck-squire{
      color: @green-icon;
    }
    .icon-circle{
      color: @magenta-icon;
    }
    .icon-triangle{
      color: @yellow-icon;
        }

    .icon-breadcrumb {
      color: @yellow-icon;
      display: flex;
      align-items: center;
    }
  }

  .search-icon {
    margin-left: 10px;
    display: inherit;

    .icon {
      margin: auto;
      height: 15px;
    }
  }

  .maint-mode, .in-call, .status {
    margin: auto;

    .icon.grey {      
      color: @grey-icon;
    }
    .icon.green {      
      color: @green-icon;
    }
    .icon.yellow {      
      color: @yellow-icon;
    }
    .icon.red {
      color: @magenta-icon;
    }

  }
}
