.customer-status {
    align-items: center;
    margin: 1rem 0 0 .7rem !important;
    p {
        margin: 0;
    }
}

.manageCustomer__status--auditInfo {
  > * {
    padding: 0 !important;
    margin-left: 18px !important;
  }
}

.align-roww > div:nth-child(1) > div:nth-child(1) > div > div:nth-child(2)  {
  @media(min-width: 768px){
    margin-left: 6px!important;
  }
  @media(min-width: 1024px){
    margin-left: 0px!important;
  }
  @media(min-width: 1200px){
    margin-left: 3px!important;
  }
}

.align-roww > div:nth-child(4) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {

  @media(min-width: 768px){
    margin-left: 6px!important;
  }
  @media(min-width: 1024px){
    margin-left: 0px!important;
  }
  @media(min-width: 1200px){
    margin-left: 3px!important;
  }
}

.row-align > div > div:nth-child(2) {
  @media(min-width: 1024px){
    margin-left: -10px!important;
  }
  @media(min-width: 1200px){
    margin-left: -6px!important;
  }
}

.align-label > div:nth-child(1)  {
  @media(min-width: 1200px){
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.interpreter-setting {
  margin-bottom: 10px;
}
.definedInCustomerAttr {
  background: none;
}

.interpreter-buttons-row {
  margin-top: 10px;
}

.update-certificate-modal {
  width: 500px !important;
}