@import "../../ui/uhe/uheVariables";
@import "../../ui/uhe/uheMixins.less";

.sign-in-table {
    td {
        white-space: nowrap;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}