.qr-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.qr-container canvas,
.qr-container svg{
  margin: 1rem 10px;
  border: 10px solid @white;
  width: 75% !important;
  height: auto !important;
  max-width: 256px;
}

.tech-button {
  width: 100px;
}

.tech-button button {
  width: 100%;
}

.tech-cell {
  padding-bottom: 20px;
  text-align: left;
}

.reset-col {
  padding-left: 0px !important;
}

.tech-page-link-icon {
  margin-left: 13px;
}

.tech-prop {
  font-weight: 600;
}

.tech-cell span.tech-prop::after {
  content: ':';
  color: #545454;
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}


.tech-container
.options-card{
  background-color: #cccccc;

  .setting-options-label {
    font-weight: 600;
  }
}

.tech-container
.options-card{
  color: #545454;
}

.modal-msg {
  text-align: center;
}

@media (max-width: 575px) {
  .options-card div.ant-col.ant-col-12.ant-form-item-label,
  .options-card div.ant-col.ant-col-12.ant-form-item-control{
  flex: 1 0 50% !important;
  }

  .tech-cell span.tech-prop::after {
    content: '';
  }

  .qr-container canvas{
    width: 50% !important;
  }

  .tech-container
  .options-card{
    div.ant-col.ant-form-item-label {
      flex: 1 0 50% !important;

    }
    div.ant-form-item-control {
      flex: 1 0 50% !important;
    }

    div.ant-row.ant-form-item.setting-options-label {
      border: solid 1px #000000 !important;
    }

    .ant-select.ant-select-single .ant-select-selection-item {
      font-size: 24px;
    }
  }
}