/**
  Unit Pages Styles
*/

.manage-unit-wrapper {
  .ant-card-head-title {
    color: #4dc8dd;
    text-transform: none !important;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
    text-align: center;
  };
  .unit-edit-card {
    .unit-card-facility {
      align-items: baseline;
      margin-top: 20px;
    };
    .error {
      margin-top: 10px;
      color: @magenta-icon;
    };
    .marginTop50 {
      margin-top: 50px;
    }

    .unit-btn {
      margin-top: 1rem;
      text-align: center;
      width: 50%;

      button {
        width: 70%;
        text-transform: uppercase;
        border: none;
        border-radius: 3px;
        padding: 5px 5px;
        cursor: pointer;
      }

      .delete-btn {
        background: @magenta-icon-lighter;
      };
      .delete-btn:hover {
        background: @magenta-icon;
      };
      .reset-btn {
        background: @grey-btn;
      };
      .reset-btn:hover {
        background: @grey-btn-darker;
      };
      .save-btn {
        background: @sidenav-hover-lighter;
      };
      .save-btn:hover {
        background: @sidenav-hover;
      };
    };
  };
}





