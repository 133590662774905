.videoFrame {
  width: 33.3%!important;
  display: inline-block;
}

.video-description {
  width: 100%;
  text-align: center;
}

.player {
  height: 100%!important;
  max-width: 100%;
    padding-left: 2%;
}

.document-link {
  display: block;
  border: none;
  box-shadow: none;
  span:first-child {
    color: #038fde;
  }
  color: #038fde;
}

.support-forms {
  width: 100%!important;
  position: relative;
}

.support-title {
  font-weight: 400!important;
  font-size: 17px!important;
  margin-left: 6px;
}

.support-title, .support-description, .gx-icon-views {
  display: inline-block;
}

.support-description {
  margin-left: 6px;
}

.gx-icon-views {
  font-size: 15px;
  margin-right: 6px;
}

.doc-items {
  width:100%!important;
  display: inline-block!important;
  margin-left: 0 !important;
}

.required-fields::before {
  display: inline-block;
  color: #f5222d;
  position: absolute;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*'
}

.section-name, .video-name {
  margin-left: 0px !important;
}

.support_delete {
  border-color: transparent;
  padding: 0px;
  margin-right: 0px!important;
}

.support_delete:active, .support_delete:hover {
  border-color: transparent;
}

.support_delete i {
  display: block
}

.upload-header-message,
.upload-header-download {
  margin-left: 0;
}

.select-file-body {
  margin: 0 !important;
  .select-file-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    span:nth-child(2) {
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > span {
      margin-right: 5px;
      color: #545454;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    &:hover,
    &:focus {
      span {
        color: #28aaeb;
      }
    }
  }
}

.support-save {
  background: #44b449;
}

.support-save:hover {
  background-color: #A5C04F!important;
  color: #545454!important;
  border-color: transparent!important;
}

.support-download {
  background-color: #FCD270 !important;
  margin-left: 10px;
	&:hover {
		background-color: #ddb65b !important;
		color: #000;
		border-color: transparent;
	}&:focus {
    color: #000;
  }
}

.support-delete {
  background: #ED67A4;
  color: #000;
  &:hover {
		background-color: #d2548d !important;
		color: #000;
		border-color: transparent;
	}&:focus {
    color: #000;
  }
}

.document-form-item {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
}

.timeStamp {
  display: flex;

  align-items: right;
}

.download-file {
  display: flex;
  align-items: center;
}

.filter-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 10px auto;
}

.video-columns {
  padding: 0px 10px;
}

.picleft {
  position: absolute;
  top: 12px; 
  right: 12px;
}

.collapses {
  position: relative;
}

.cards {
  width: 50%;
  border-radius: 3px;
  border-color: #7d7d7d;
}