.bulkUpload__instructions {
  margin: 5px 5px 5px 10px;

  .bulkUpload__instructions--listItem {
    display: flex;
    align-items: center;

    * {
      display: inline-block;
      margin: 0;
    }

    button {
      margin-left: 20px;
    }

    .downloadBtn {
      background-color: #0092D7;
      font-size: 16px;
      font-weight: bold;
      border: none;
      color: #FFFFFF;
      &:not([disabled]):hover {
        background-color: #49B2FF;
      }
      &[disabled] {
        background-color: #003A56;
        color: #666666
      }
    }
  }

  .ant-list-item {
    border-bottom: @main-border-separator;
  }
}
